<script setup lang="ts">
import { useVModel, whenever } from '@vueuse/core';
import { SearchResponse } from 'instantsearch.js';
import { inject, onMounted, ref } from 'vue';

import { AlgoliaSuggestedSearch, AlgoliaVariant, init as initAlgoliaClient } from '@/api/algolia';
import PopularProducts from '@/components/layout/header/search/PopularProducts.vue';
import RecentSearches from '@/components/layout/header/search/RecentSearches.vue';
import SearchSuggestions from '@/components/layout/header/search/SearchSuggestions.vue';
import { sendExperimentViewedEvent } from '@/utils/analytics/experimentViewedEvent';

const props = defineProps<{
  recentSearches: string[];
  query: string;
}>();
const emit = defineEmits<{
  search: [query: string];
  'update:recentSearches': [string[]];
}>();

const algoliaClient = inject('algoliaClient', () => initAlgoliaClient(), true);

const productResults = ref<SearchResponse<AlgoliaVariant>>();
const suggestedSearches = ref<SearchResponse<AlgoliaSuggestedSearch>>();

const recentSearches = useVModel(props, 'recentSearches', emit);

const handleClick = (search: string) => {
  emit('search', search);
};

onMounted(async () => {
  const productsIndex = algoliaClient.initIndex('Products');
  const suggestionsIndex = algoliaClient.initIndex('Products_query_suggestions');

  whenever(
    () => props.query,
    async (value) => {
      const [suggestions, products] = await Promise.all([
        suggestionsIndex.search<AlgoliaSuggestedSearch>(value, {
          hitsPerPage: 6,
        }),
        productsIndex.search<AlgoliaVariant>(value, {
          analytics: true,
          distinct: true,
          filters: 'traits:searchable',
          getRankingInfo: true,
          hitsPerPage: 9,
        }),
      ]);

      suggestedSearches.value = suggestions;
      productResults.value = products;

      if (productResults.value?.abTestID) {
        sendExperimentViewedEvent(
          {
            experiment_id: `${productResults.value.abTestID}`,
            variation_id: `${productResults.value.abTestVariantID!}`,
          },
          'Algolia',
        );
      }
    },
  );
});
</script>

<template>
  <div class="flex flex-col gap-4" :class="{ 'py-4': query }">
    <RecentSearches
      v-show="!query && recentSearches.length"
      v-model="recentSearches"
      class="py-4"
      @click="handleClick"
    />
    <SearchSuggestions
      v-if="suggestedSearches"
      v-show="query"
      v-model:recentSearches="recentSearches"
      :query="query"
      :suggestions="suggestedSearches.hits"
      @click="handleClick"
    />
    <PopularProducts
      v-if="productResults?.hits.length"
      v-show="query"
      :products="productResults.hits"
    />
  </div>
</template>
