/* eslint-disable import/prefer-default-export */

import { Cart, CartUpdateAction, Order } from '@commercetools/platform-sdk';
import { computed, Ref } from 'vue';
import { Store } from 'vuex';

import { useCart } from '@/composables/useCart';
import { useDelivery } from '@/composables/useDelivery';
import {
  CartDiscountWithSiteMessages,
  getAppliedCartDiscountIds,
  getCartDiscountsForMessages,
} from '@/lib/cart/discounts';
import { useCustomer } from '@/stores/customer';
import { isGiftLineItem } from '@/utils/cart';

export function useDiscount(store: Store<any>, alternativeCart?: Ref<Cart | Order | undefined>) {
  const {
    cart,
    cartCustomFields,
    cartDiscountCodes,
    lineItems,
    pendingCartDiscountCodes,
    unassignedLineItems,
    updateCart,
  } = useCart(store, alternativeCart);
  const customerStore = useCustomer();
  const { clearEmptyShipments, standardShipments, updateShipment } = useDelivery(
    store,
    alternativeCart,
  );

  const appliedCartDiscountIds = computed(() => getAppliedCartDiscountIds(lineItems.value));

  const appliedDiscountCodes = computed(() =>
    cartDiscountCodes.value.map((discount) => ({
      code: discount.code,
      label: discount.name?.en ?? '',
    })),
  );

  const pendingDiscountCodes = computed(() =>
    pendingCartDiscountCodes.value.map((discount) => ({
      label: discount.name?.en ?? '',
      message: discount.description,
    })),
  );

  const availableDiscountCodes = computed<{ code: string; label: string }[]>(() => {
    const discounts = [];

    const { stashedDiscountCode, stashedDiscountCodeName } = cartCustomFields.value;
    if (stashedDiscountCode && stashedDiscountCodeName) {
      discounts.push({
        code: stashedDiscountCode,
        label: stashedDiscountCodeName,
      });
    }

    const [appliedDiscountCode] = appliedDiscountCodes.value;
    if (
      appliedDiscountCode &&
      !customerStore.customerDiscountCodes.find((d) => d.code === appliedDiscountCode.code)
    ) {
      discounts.push(appliedDiscountCode);
    }

    const [pendingDiscountCode] = pendingDiscountCodes.value;

    discounts.push(
      ...customerStore.customerDiscountCodes.map((d) => ({
        code: d.code,
        label: d.name?.en ?? '',
      })),
    );

    return discounts;
  });

  const cartDiscountsForMessages = computed<CartDiscountWithSiteMessages[]>(() =>
    getCartDiscountsForMessages(cart.value),
  );

  return {
    appliedCartDiscountIds,
    appliedDiscountCodes,
    availableDiscountCodes,
    cartDiscountsForMessages,
    pendingDiscountCodes,

    applyDiscountCode: async (code: string) => {
      await updateCart(() => {
        const actions: CartUpdateAction[] = [];

        const permanentCodes = customerStore.customerDiscountCodes.map((d) => d.code);
        let stashExistingCode = false;
        const { stashedDiscountCode } = cartCustomFields.value;
        const [appliedDiscountCode] = appliedDiscountCodes.value;
        const clearStashedCode = code === stashedDiscountCode;
        if (appliedDiscountCode) {
          const discountCode = cartDiscountCodes.value.find(
            (d) => d.code === appliedDiscountCode.code,
          );
          if (discountCode) {
            actions.push({
              action: 'removeDiscountCode',
              discountCode: {
                id: discountCode.id,
                typeId: 'discount-code',
              },
            });
            if (!permanentCodes.includes(appliedDiscountCode.code)) {
              stashExistingCode = true;
            }
          }
        }

        if (clearStashedCode || stashExistingCode) {
          actions.push(
            {
              action: 'setCustomField',
              name: 'stashedDiscountCode',
              value: stashExistingCode ? appliedDiscountCode.code : undefined,
            },
            {
              action: 'setCustomField',
              name: 'stashedDiscountCodeName',
              value: stashExistingCode ? appliedDiscountCode.label : undefined,
            },
          );
        }

        actions.push({ action: 'addDiscountCode', code });

        return actions;
      });

      const unassignedGifts =
        unassignedLineItems.value.length && unassignedLineItems.value.every(isGiftLineItem);
      if (unassignedGifts && standardShipments.value.length === 1) {
        const [shipment] = standardShipments.value;
        const { address, key } = shipment;
        const updatedLineItems = [...shipment.lineItems, unassignedLineItems.value[0]];
        const lineQuantities = updatedLineItems.reduce(
          (items, l) => ({ ...items, [l.id]: l.quantity }),
          {},
        );

        await updateShipment(key, address, lineQuantities);
      }
    },

    removeDiscountCode: async (code: string) => {
      await updateCart(() => {
        const actions: CartUpdateAction[] = [];

        const [appliedDiscountCode] = appliedDiscountCodes.value;
        if (!customerStore.customerDiscountCodes.find((d) => d.code === appliedDiscountCode.code)) {
          actions.push(
            {
              action: 'setCustomField',
              name: 'stashedDiscountCode',
              value: appliedDiscountCode.code,
            },
            {
              action: 'setCustomField',
              name: 'stashedDiscountCodeName',
              value: appliedDiscountCode.label,
            },
          );
        }

        const discountCode = cartDiscountCodes.value.find((d) => d.code === code);
        if (discountCode) {
          actions.push({
            action: 'removeDiscountCode',
            discountCode: {
              id: discountCode.id,
              typeId: 'discount-code',
            },
          });
        }

        return actions;
      });

      await clearEmptyShipments();
    },
  };
}
