import { computed, reactive, ref } from 'vue';
import { RouteLocationNormalized } from 'vue-router';

import { getRecommendations } from '@/api/customer';
import { useDyChooseResults } from '@/composables/dynamic-yield/experiences';
import {
  BaseChoice,
  DyRecommendationsReference,
  RecommendationsChoice,
  RecommendationsConfigChoice,
  RecommendationsReferenceVariation,
  RecommendationsVariation,
  WebstoreRecommendationsReference,
} from '@/lib/personalization/dynamicYield';
import { WebstoreRecommendation } from '@/lib/personalization/webstore';
import { reportError } from '@/utils/reportError';

export type ExpandedRecommendationsReference = DyRecommendationsReference &
  RecommendationsVariation & {
    decisionId: BaseChoice['decisionId'];
    type: 'ExpandedRecommendationsReference';
  };
export type ExpandedWebstoreRecommendationsReference = WebstoreRecommendationsReference & {
  recommendations: WebstoreRecommendation[];
  type: 'ExpandedWebstoreRecommendationsReference';
};

type RecommendationsChoiceOrConfigChoice = RecommendationsChoice | RecommendationsConfigChoice;

type WebstoreRecommendationEndpointSpec =
  WebstoreRecommendationsReference['recommendationsEndpoint'];
function fetchWebstoreRecommendations(webstoreEndpoints: WebstoreRecommendationEndpointSpec[]) {
  return Promise.all(
    webstoreEndpoints.map(async (endpoint) => {
      const { path, params } = endpoint;
      const recommendations = await getRecommendations<WebstoreRecommendation>(path, params);
      return { path, recommendations };
    }),
  );
}

function isJsonChoice(
  choice?: RecommendationsChoiceOrConfigChoice,
): choice is RecommendationsConfigChoice {
  return choice?.type === 'DECISION';
}

function isRecommendationsChoice(
  choice?: RecommendationsChoiceOrConfigChoice,
): choice is RecommendationsChoice {
  return choice?.type === 'RECS_DECISION';
}

function isValidDyReference(
  reference: RecommendationsReferenceVariation['payload']['data']['config'][number],
): reference is DyRecommendationsReference {
  return 'dySelector' in reference && !!reference.dySelector;
}

function isWebstoreReference(
  reference: RecommendationsReferenceVariation['payload']['data']['config'][number],
): reference is WebstoreRecommendationsReference {
  return 'recommendationsEndpoint' in reference;
}

type KeyedChoices<T> = Record<string, T | undefined>;
interface Options<T> {
  preloadedChoices?: KeyedChoices<T>;
  preloadedWebstoreRecommendations?: KeyedChoices<WebstoreRecommendation[]>;
  syncContextWithRoute?: boolean;
}
export const useRecommendationsFromConfig = (
  configExperienceName: `[CONFIG] ${string}`,
  route?: RouteLocationNormalized,
  options?: Options<RecommendationsChoiceOrConfigChoice>,
) => {
  const {
    choicesByName,
    isPending: dyPending,
    loadExperiences,
    pageContext,
  } = useDyChooseResults<RecommendationsChoiceOrConfigChoice>(configExperienceName, route, options);
  const config = computed(() => {
    const configChoice = choicesByName[configExperienceName];
    if (!isJsonChoice(configChoice)) return undefined;
    const [configVariation] = configChoice.variations;
    return configVariation?.payload.data.config;
  });

  const referencesByType = computed(() => {
    const references: {
      DY: DyRecommendationsReference[];
      webstore: WebstoreRecommendationsReference[];
    } = {
      DY: [],
      webstore: [],
    };

    config.value?.forEach((reference) => {
      if (isValidDyReference(reference)) {
        references.DY.push(reference);
      } else if (isWebstoreReference(reference)) {
        references.webstore.push(reference);
      }
    });

    return references;
  });

  const webstorePending = ref(false);
  const webstoreRecommendations = reactive(options?.preloadedWebstoreRecommendations ?? {});
  const loadWebstoreRecommendations = async (skipLoadedRecommendations?: boolean) => {
    let webstoreEndpoints = referencesByType.value.webstore.map(
      (reference) => reference.recommendationsEndpoint,
    );
    if (skipLoadedRecommendations) {
      webstoreEndpoints = webstoreEndpoints.filter(
        (endpoint) => !webstoreRecommendations[endpoint.path],
      );
    }
    if (!webstoreEndpoints.length) return;
    webstorePending.value = true;
    try {
      const webstoreResponses = await fetchWebstoreRecommendations(webstoreEndpoints);
      webstoreResponses.forEach(({ path, recommendations }) => {
        webstoreRecommendations[path] = recommendations;
      });
    } catch (err) {
      reportError(err);
    }
    webstorePending.value = false;
  };

  const loadFromConfig = async (skipLoadedExperiences?: boolean) => {
    await loadExperiences({ skipLoadedExperiences });
    const experienceNames = referencesByType.value.DY.map((reference) => reference.dySelector);
    await Promise.all([
      loadExperiences({
        expandingConfig: configExperienceName,
        newExperiences: experienceNames,
        skipLoadedExperiences,
      }),
      loadWebstoreRecommendations(skipLoadedExperiences),
    ]);
  };

  const recommendationRows = computed(() => {
    if (!config.value) return [];
    const recommendations: (
      | ExpandedRecommendationsReference
      | ExpandedWebstoreRecommendationsReference
    )[] = [];

    config.value.forEach((reference) => {
      if (isValidDyReference(reference)) {
        const choice = choicesByName[reference.dySelector];
        if (!isRecommendationsChoice(choice) || !choice.variations.length) return;
        const [variation] = choice.variations;
        recommendations.push({
          ...reference,
          ...variation,
          decisionId: choice.decisionId,
          type: 'ExpandedRecommendationsReference',
        });
      } else if (isWebstoreReference(reference)) {
        const webstoreRecommendation =
          webstoreRecommendations[reference.recommendationsEndpoint.path];
        if (webstoreRecommendation)
          recommendations.push({
            ...reference,
            recommendations: webstoreRecommendation,
            type: 'ExpandedWebstoreRecommendationsReference',
          });
      }
    });

    return recommendations;
  });

  const isPending = computed(() => dyPending.value || webstorePending.value);

  return {
    isPending,
    loadFromConfig,
    pageContext,
    recommendationRows,
  };
};
