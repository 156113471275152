<script setup lang="ts">
import { Subscription } from '@nuts/auto-delivery-sdk';
import { computed } from 'vue';

import BaseBodyText from '@/components/base/typography/BaseBodyText.vue';
import Caption from '@/components/base/typography/Caption.vue';
import Header5 from '@/components/base/typography/Header5.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import LineItemImage from '@/components/cart/added-modal/LineItemImage.vue';
import money from '@/filters/money';
import {
  BuiltProposedDelivery,
  getAutoDeliveryDetailsPath,
  getSubscriptionImage,
} from '@/utils/autoDelivery';
import { Money } from '@/utils/money';

const props = defineProps<{
  delivery: BuiltProposedDelivery;
  subscription: Subscription;
}>();

const autoDeliveryFrequencyText = computed(() => {
  if (props.subscription.interval) {
    return `${props.subscription.interval / 7} Week Frequency`;
  }
  return 'One time';
});

const hasDiscount = computed(() => {
  const { discountedPiecePrice, piecePrice } = props.subscription;
  return !Money.equals(discountedPiecePrice, piecePrice);
});
</script>

<template>
  <div class="flex justify-between gap-2">
    <div class="flex gap-2">
      <LineItemImage
        :isPending="false"
        :linksToPdp="false"
        :name="subscription.name"
        :productPath="subscription.productPath"
        :titleImage="getSubscriptionImage(subscription)"
      />
      <div class="flex flex-col md:hidden">
        <BaseBodyText class="font-semibold">
          {{ subscription.name.en }}
        </BaseBodyText>
        <SmallBodyText class="mt-0.5">
          Auto-Delivery - <b>{{ autoDeliveryFrequencyText }}</b>
        </SmallBodyText>
        <div class="flex items-center gap-2 mt-2">
          <BaseBodyText class="text-neutral-500">Qty: {{ subscription.quantity }}</BaseBodyText>
          <Header5 class="font-semibold">
            {{ money(subscription.discountedExtendedPrice) }}
          </Header5>
          <BaseBodyText v-if="hasDiscount" class="line-through text-neutral-500">
            {{ money(subscription.extendedPrice) }}
          </BaseBodyText>
        </div>
      </div>

      <div class="flex-col hidden md:visible md:flex">
        <BaseBodyText class="font-semibold">
          Auto-Delivery - {{ autoDeliveryFrequencyText }}
        </BaseBodyText>
        <BaseBodyText class="text-neutral-500 mt-0.5">
          Qty: {{ subscription.quantity }}
        </BaseBodyText>
        <RouterLink
          class="mt-1"
          :to="getAutoDeliveryDetailsPath(delivery)"
          @click="$emit('manage')"
        >
          <Caption class="underline text-nuts-cyan-700 hover:font-semibold">
            Manage Auto-Delivery
          </Caption>
        </RouterLink>
      </div>
    </div>
    <div class="hidden md:flex md:visible items-center md:flex-row md:gap-1.5">
      <Header5 class="font-semibold">
        {{ money(subscription.discountedExtendedPrice) }}
      </Header5>
      <BaseBodyText v-if="hasDiscount" class="line-through text-neutral-500">
        {{ money(subscription.extendedPrice) }}
      </BaseBodyText>
    </div>
  </div>
</template>
