/* eslint-disable import/prefer-default-export */
import { RouteLocationNormalized, Router } from 'vue-router';

import { deriveDyContext } from '@/router/createRouter';
import { setRudderTyperOptions } from '@/rudder-typer';
import { Options as RudderOptions } from '@/rudder-typer/rudder';
import { gtag } from '@/utils/analytics';

/**
 * Additional fields we attach to each track event's context.page
 */
interface NutsTrackPageContext {
  category?: string;
  name?: string;
  referrer?: string;
}

export function installPageEvents(router: Router) {
  let firstRoute = true;
  let trackPageContext: NutsTrackPageContext | undefined;

  function sendSpaStart(to: RouteLocationNormalized) {
    if (window.DY?.API && !firstRoute) {
      window.DY.API('spa_start', {
        context: deriveDyContext(to),
        url: to.path,
        countAsPageview: true,
      });
    }
  }

  function sendPageEvent(to: RouteLocationNormalized, from: RouteLocationNormalized) {
    if (window.DY?.API && !firstRoute) {
      window.DY.API('spa_end', {
        context: deriveDyContext(to),
        url: to.path,
        countAsPageview: true,
      });
    }

    if (to.meta.gtagPageType) gtag('set', 'content_group', `${to.meta.gtagPageType}`);

    const pushChangeEvent = new CustomEvent<{
      from?: RouteLocationNormalized;
      to: RouteLocationNormalized;
    }>('onRoutingComplete', {
      detail: { to, from },
    });
    document.dispatchEvent(pushChangeEvent);

    if (to.path !== from?.path || (to.path === '/' && from?.path === '/')) {
      let rudderstackPageName: string | undefined = to.meta.gtagPageType;
      const routeComponent = to.matched[0]?.components?.default;
      if (routeComponent) {
        // @ts-expect-error - `__name` should exist when the matching component uses `<script setup>`
        // eslint-disable-next-line no-underscore-dangle
        const componentName: string | undefined = routeComponent.__name ?? routeComponent.name;
        if (componentName) {
          rudderstackPageName = componentName;
        }
      }

      const category = to.meta.gtagPageType;
      const name = rudderstackPageName;
      const payload: { path: string; url: string; referrer?: string } = {
        path: to.path,
        url: `${window.location.origin}${to.fullPath}`,
      };
      trackPageContext = {
        category,
        name,
      };

      // manually set for all spa navigation, otherwise let RS take care of it
      if (!firstRoute) {
        payload.referrer = `${window.location.origin}${from.fullPath}`;
        trackPageContext.referrer = payload.referrer;
      }

      window.rudderanalytics?.page(category, name, payload);

      firstRoute = false;
    }
  }

  /**
   * Copy `trackPageContext` into `context.page` but let any existing values win
   */
  function withPageContext(options: RudderOptions): RudderOptions {
    return {
      ...options,
      context: { ...options.context, page: { ...trackPageContext, ...options.context?.page } },
    };
  }

  router.beforeResolve(sendSpaStart);
  router.afterEach(sendPageEvent);

  setRudderTyperOptions({
    analytics: {
      // since this will only be used by RudderTyper and it _always_ passes 3+
      // arguments, we don't need to handle all the combinations of optional
      // arguments.
      track(event: string, properties: object, options: RudderOptions, callback?: Function): void {
        window.rudderanalytics?.track(event, properties, withPageContext(options), callback);
      },
    },
  });
}
