import type { Address as CtAddress } from '@commercetools/platform-sdk';
import {
  IWebstoreAddress as WebstoreAddress,
  IWebstoreAddressDraft as WebstoreAddressDraft,
} from '@nuts/auto-delivery-sdk';
import { PayPalTokenizePayload } from 'braintree-web';
import pickBy from 'lodash/pickBy';
import type { Address as PayPalCheckoutAddress } from 'paypal-checkout-components';

import type { ExperianFormatResponse } from '@/api/experian';
import {
  BraintreeAddress,
  USBankAccountDetailsBusiness,
  USBankAccountDetailsPersonal,
} from '@/stores/braintree';

export { CtAddress, WebstoreAddress, WebstoreAddressDraft };

export interface NutsAddress
  extends Pick<
    CtAddress,
    | 'id'
    | 'key'
    | 'postalCode'
    | 'city'
    | 'state'
    | 'country'
    | 'company'
    | 'phone'
    | 'email'
    | 'externalId'
  > {
  readonly default?: boolean;
  readonly name?: string;
  readonly street1?: string;
  readonly street2?: string;
  readonly residential?: boolean;
  readonly signedTrustedMatchkey?: string;
}

interface PayPalAddress {
  city: string;
  country_code: string;
  line1?: string;
  line2?: string;
  phone?: string;
  postal_code: string;
  postalCode?: string;
  recipientName?: string;
  state: string;
}

export const NutsAddress = {
  fromApplePay(contact: ApplePayJS.ApplePayPaymentContact): NutsAddress {
    return {
      name: `${contact.givenName ?? ''} ${contact.familyName ?? ''}`.trim(),
      street1: contact.addressLines?.[0] ?? '',
      street2: contact.addressLines?.[1] ?? '',
      city: contact.locality,
      state: contact.administrativeArea?.toUpperCase(),
      postalCode: contact.postalCode,
      country: contact.countryCode?.toUpperCase() ?? 'US',
      email: contact.emailAddress ?? '',
      phone: contact.phoneNumber ?? '',
    };
  },

  fromBraintree(
    braintreeAccount: USBankAccountDetailsBusiness | USBankAccountDetailsPersonal,
  ): NutsAddress {
    const { billingAddress } = braintreeAccount;

    return {
      name:
        'firstName' in braintreeAccount && 'lastName' in braintreeAccount
          ? `${braintreeAccount.firstName ?? ''} ${braintreeAccount.lastName ?? ''}`.trim()
          : '',
      company: 'businessName' in braintreeAccount ? braintreeAccount.businessName : '',
      street1: billingAddress.streetAddress,
      street2: billingAddress.extendedAddress,
      city: billingAddress.locality,
      state: billingAddress.region,
      postalCode: billingAddress.postalCode,
      country: 'US',
    };
  },

  fromCt(ctAddress: CtAddress): NutsAddress {
    const {
      lastName,
      streetName,
      additionalStreetInfo,
      additionalAddressInfo,
      custom,
      country,
      ...other
    } = ctAddress;

    return {
      ...pickBy({
        ...other,
        name: lastName,
        street1: streetName,
        street2: additionalStreetInfo,
        residential: additionalAddressInfo ? additionalAddressInfo === 'residential' : undefined,
        signedTrustedMatchkey: custom?.fields.signedTrustedMatchkey,
      }),
      country,
    };
  },

  fromExperian(formatContext: ExperianFormatResponse, countryCode?: string): NutsAddress {
    const { result } = formatContext;
    const { delivery_type: deliveryType } = formatContext.metadata?.address_classification || {};
    let residential;
    if (deliveryType) {
      residential = deliveryType === 'residential';
    }
    return {
      street1: result.address.address_line_1,
      street2: result.address.address_line_2 ?? undefined,
      city: result.address.locality,
      country: countryCode ?? 'US',
      postalCode: result.address.postal_code,
      residential,
      state: result.address.region,
    };
  },

  fromPayPal(payPalAddress: PayPalAddress | PayPalCheckoutAddress): NutsAddress {
    return {
      city: payPalAddress.city,
      country:
        'countryCode' in payPalAddress ? payPalAddress.countryCode : payPalAddress.country_code,
      name: payPalAddress.recipientName,
      phone: payPalAddress.phone,
      postalCode:
        'postalCode' in payPalAddress ? payPalAddress.postalCode : payPalAddress.postal_code,
      state: payPalAddress.state,
      street1: payPalAddress.line1,
      street2: payPalAddress.line2,
    };
  },

  fromPayPalTokenization(tokenizationDetails: PayPalTokenizePayload['details']): NutsAddress {
    const { billingAddress, firstName, lastName } = tokenizationDetails;
    const { countryCode, line1, line2, ...other } = billingAddress;
    return {
      ...pickBy({
        ...other,
        name: [firstName, lastName].filter(Boolean).join(' '),
        street1: line1,
        street2: line2,
      }),
      country: countryCode,
    };
  },

  fromWebstore(webstoreAddress: WebstoreAddress): NutsAddress {
    const { id, countryCode, phone1, stateProv, ...other } = webstoreAddress;

    return {
      id: String(id),
      externalId: String(id),
      ...pickBy({
        ...other,
        phone: phone1,
        state: stateProv,
      }),
      country: countryCode || 'US',
    };
  },

  toBraintree(address: NutsAddress): BraintreeAddress {
    return {
      streetAddress: address.street1 ?? '',
      extendedAddress: address.street2,
      locality: address.city ?? '',
      region: address.state ?? '',
      postalCode: address.postalCode ?? '',
    };
  },

  toCt(address: NutsAddress): CtAddress {
    const { name, street1, street2, residential, country, ...other } = address;

    let additionalAddressInfo;
    if (residential !== undefined) {
      additionalAddressInfo = residential ? 'residential' : 'business';
    }

    return {
      ...pickBy({
        ...other,
        lastName: name,
        streetName: street1,
        additionalStreetInfo: street2,
        additionalAddressInfo,
      }),
      country,
    };
  },

  toWebstore(address: NutsAddress): WebstoreAddressDraft {
    const {
      name,
      company,
      street1,
      street2,
      city,
      state,
      postalCode,
      country,
      residential,
      email,
      phone,
    } = address;

    return {
      company,
      postalCode: postalCode ?? '',
      city: city ?? '',
      countryCode: country,
      phone1: phone,
      email: email ?? '',
      name: name ?? '',
      stateProv: state ?? '',
      street1,
      street2,
      residential,
    };
  },
};

export const States: { readonly [key: string]: string } = {
  AK: 'Alaska',
  AL: 'Alabama',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  IA: 'Iowa',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MA: 'Massachusetts',
  MD: 'Maryland',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  VI: 'Virgin Islands',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
  AA: 'AA',
  AE: 'AE',
  AP: 'AP',
  AS: 'American Samoa',
  FM: 'FM',
  MH: 'MH',
  MP: 'MP',
  PW: 'PW',
};

export const CanadianProvinces: { readonly [key: string]: string } = {
  AB: 'Alberta',
  BC: 'British Columbia',
  MB: 'Manitoba',
  NB: 'New Brunswick',
  NL: 'Newfoundland and Labrador',
  NS: 'Nova Scotia',
  NT: 'Northwest Territories',
  NU: 'Nunavut',
  ON: 'Ontario',
  PE: 'Prince Edwards Island',
  QC: 'Quebec',
  SK: 'Saskatchewan',
  YT: 'Yukon',
};

const countryAlphaCodes: { readonly [alpha2: string]: string } = {
  US: 'USA',
  CA: 'CAN',
};

export const CountryCodes = {
  fromAlpha2(alpha2: string): string | null {
    return countryAlphaCodes[alpha2] ?? null;
  },
  fromAlpha3(alpha3: string): string | null {
    return Object.keys(countryAlphaCodes).find((k) => countryAlphaCodes[k] === alpha3) ?? null;
  },
};

export function addressFieldVariationsForCountry(countryCode: string) {
  const fields: { [key: string]: string | null } = {
    postalCode: 'ZIP code',
    postalCodeName: 'zipCode',
    state: 'State',
    stateName: 'state',
  };
  switch (countryCode) {
    case 'CA':
      fields.postalCode = 'Postal code';
      fields.postalCodeName = 'postalCode';
      fields.state = 'Province';
      fields.stateName = 'province';
      break;
    default:
      break;
  }
  return fields;
}

export function statesForCountry(countryCode: string) {
  let dataSet;
  switch (countryCode) {
    case 'CA':
      dataSet = CanadianProvinces;
      break;
    case 'US':
    default:
      dataSet = States;
      break;
  }
  return { ...dataSet };
}

export function validationRulesForCountry(countryCode: string) {
  let rules;

  switch (countryCode) {
    case 'CA':
      rules = {
        postalCode: {
          regex: /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ][ -]?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i,
          required: true,
        },
      };
      break;
    case 'US':
    default:
      rules = {
        postalCode: {
          regex: /^\d{5}(-?\d{4})?$/,
          required: true,
        },
      };
      break;
  }
  return rules;
}

export function abbreviateStateIfNeeded(shippingAddress: NutsAddress) {
  let addressState = shippingAddress.state;
  if (addressState && addressState?.length > 2) {
    const listStates = shippingAddress.country?.toUpperCase() === 'US' ? States : CanadianProvinces;
    const stateKeyAndValue = Object.entries(listStates).find(
      (stateArray) => stateArray[1].toUpperCase() === addressState,
    );
    addressState = stateKeyAndValue ? stateKeyAndValue[0] : addressState;
  }
  return addressState;
}
