import { useEventBus, UseEventBusReturn } from '@vueuse/core';
import createDebug from 'debug';
import qs from 'qs';
import {
  createMemoryHistory,
  createRouter as createVueRouter,
  createWebHistory,
  LocationQuery,
  NavigationGuard,
  RouteLocation,
  RouteLocationNormalized,
  Router,
  RouteRecordNormalized,
  RouteRecordRaw,
} from 'vue-router';

import { fromNutsJson, NutsJson, webstore } from '@/api';
import { ChooseParams } from '@/api/dynamic-yield/experiences';
import { PageTypeMetadata, routes } from '@/router/routes';
import { installPageEvents } from '@/utils/analytics/pageEvent';
import { sendProductListViewedEvent } from '@/utils/analytics/productListViewedEvent';
import detectIE from '@/utils/browser';

// Views
const CLP = () => import('@/views/CLP.vue');
const CMS = () => import('@/views/CMS.vue');
const CustomTrays = () => import('@/views/CustomTrays.vue');
const MagicLink = () => import('@/views/MagicLink.vue');
const CustomMixes = () => import('@/views/CustomMixes.vue');
const PDP = () => import('@/views/PDP.vue');
const PLP = () => import('@/views/PLP.vue');
const Refer = () => import('@/views/Refer.vue');
const ResetPassword = () => import('@/views/ResetPassword.vue');
const Theme404 = () => import('@/components/homepage/Theme404.vue');

// Layout
const CheckoutFooter = () => import('@/components/layout/footer/CheckoutFooter.vue');
const SimplifiedHeader = () => import('@/components/layout/header/SimplifiedHeader.vue');

const debug = createDebug('nuts:createRouter');
const isBrowser = typeof window !== 'undefined';

const metaByComponentName: Record<string, PageTypeMetadata['meta']> = {
  CLP: {
    dyPageType: 'CATEGORY',
    gtagPageType: 'CLP',
  },
  CustomTrays: {
    dyPageType: 'PRODUCT',
    gtagPageType: 'PDP',
    webstoreAssets: {
      css: [nutshell['css/custom-trays.css']],
    },
  },
  CustomMixes: {
    dyPageType: 'PRODUCT',
    gtagPageType: 'PDP',
  },
  PDP: {
    dyPageType: 'PRODUCT',
    gtagPageType: 'PDP',
  },
  PLP: {
    dyPageType: 'CATEGORY',
    gtagPageType: 'PLP',
    webstoreAssets: {
      css: [nutshell['css/instant-search.css']],
    },
  },
  Theme404: {
    dyPageType: 'HOMEPAGE',
    gtagPageType: 'HP',
  },
};

const pageComponents = {
  CLP,
  CMS,
  CustomTrays,
  MagicLink: {
    default: MagicLink,
    pageFooter: CheckoutFooter,
    pageHeader: SimplifiedHeader,
  },
  CustomMixes,
  PDP,
  PLP,
  Refer,
  ResetPassword: {
    default: ResetPassword,
    pageFooter: CheckoutFooter,
    pageHeader: SimplifiedHeader,
  },
  Theme404,
};

export interface RouteSpec extends Pick<RouteRecordNormalized, 'path' | 'props'> {
  componentName: keyof typeof pageComponents;
  meta?: {
    dyPageData?: string[];
  };
}

interface CustomMeta {
  meta: {
    /**
     * Specify if the route should be removed once the user navigates away from it.
     */
    removeAfterNavigation?: boolean;
  };
}

class Redirect extends Error {
  constructor(public redirectTo: string) {
    super();
  }
}

export function addResolvedRoute(
  router: Router,
  { componentName, meta, path, props = {} }: RouteSpec,
  supportedPageTypes?: string[],
) {
  if (supportedPageTypes && !supportedPageTypes.includes(componentName)) {
    throw new Error(`Page component not yet supported for SPA: ${componentName}`);
  }
  debug('storing resolved route %s to %s with props: %o', path, componentName, props);

  const defaultMeta = metaByComponentName[componentName] ?? {
    dyPageType: componentName === 'CMS' && path === '/' ? 'HOMEPAGE' : 'OTHER',
    gtagPageType: componentName === 'CMS' && path === '/' ? 'HP' : undefined,
  };

  const component = pageComponents[componentName];
  const removeAfterNavigation = ['MagicLink', 'ResetPassword'].includes(componentName);

  const routeConfig: RouteRecordRaw & PageTypeMetadata & CustomMeta = {
    name: removeAfterNavigation ? componentName : undefined,
    path,
    components: typeof component === 'function' ? { default: component } : component,
    meta: {
      ...defaultMeta,
      dyPageData: meta?.dyPageData,
      removeAfterNavigation,
      requiresBeforeUpdateDelegation: componentName === 'PLP',
    },
    props: { default: props },
  };
  router.addRoute({ ...routeConfig, path: path.replaceAll(':', '\\:') });
  return routeConfig;
}

type ResolvePathResponse = Omit<RouteSpec, 'path'>;
export const resolveWebstorePath = async (path: string): Promise<RouteSpec> => {
  const {
    data,
    request: { responseURL },
  } = await webstore.get<ResolvePathResponse | NutsJson<ResolvePathResponse>>(
    `/api/resolve-path?path=${path}`,
  );

  if (!responseURL) {
    throw new Error('attempted SPA navigation with a browser that cannot detect redirections');
  }
  const url = new URL(responseURL);
  const finalPath = url.searchParams.get('path');

  // non-SPA redirect
  if (url.pathname !== '/api/resolve-path' || !finalPath) {
    throw new Redirect(`${url.pathname}${url.search}`);
  }

  // TODO: Very soon this function should be simplified to assume the response is always NutsJson
  const response = data && 'data' in data ? await fromNutsJson(data) : data;
  return { path: finalPath, ...response };
};

export const createCatchallGuard =
  (router: Router, supportedPageTypes: string[]): NavigationGuard =>
  async (to, from, next) => {
    if (!isBrowser) {
      next(new Error(`PHP allowed bad path: ${to.path}`));
      return;
    }
    try {
      debug('this route has not been resolved yet, checking webstore');

      const resolved = await resolveWebstorePath(to.path);

      const route = addResolvedRoute(router, resolved, supportedPageTypes);

      if (route.path !== to.path) {
        // SPA redirect? memorize it
        router.addRoute({ path: to.path, redirect: route.path });
      }

      next(route.path);
    } catch (e) {
      debug('failed to route locally, assigning location and letting normal render take over!', e);
      window.location.assign(e instanceof Redirect ? e.redirectTo : to.fullPath);
    }
  };

export function deriveDyContext(route: RouteLocationNormalized): ChooseParams['pageContext'] {
  return {
    data: route.meta.dyPageData ?? [],
    type: route.meta.dyPageType || 'OTHER',
  };
}

export const createRouter = (initialRoute?: RouteSpec, additionalRouteTypes = [] as string[]) => {
  let eventBus: UseEventBusReturn<any, any> | undefined;
  if (isBrowser) eventBus = useEventBus('delegated-router-nav-guards');
  const supportedPageTypes = [
    'CLP',
    'CMS',
    'PDP',
    'PLP',
    'CustomMixes',
    'CustomTrays',
    'Theme404',
    ...additionalRouteTypes,
  ];

  const router = createVueRouter({
    history: typeof window === 'undefined' ? createMemoryHistory() : createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
      const involvesCheckout = (r: RouteLocation) => r.path.startsWith('/checkout');
      if (savedPosition) {
        return savedPosition;
      }
      if (to.hash) {
        return {
          el: to.hash,
          behavior: 'smooth',
        };
      }
      if ([to, from].every(involvesCheckout) || to.path === from.path) {
        return false;
      }
      return { top: 0 };
    },
    parseQuery(query) {
      return qs.parse(query) as unknown as LocationQuery;
    },
    stringifyQuery(query) {
      const result = qs.stringify(query);
      return result || '';
    },
  });

  router.addRoute({
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: Theme404,
    meta: { resolveByWebstore: true },
    beforeEnter: createCatchallGuard(router, supportedPageTypes),
  });
  if (initialRoute) {
    addResolvedRoute(router, initialRoute);
  }

  router.beforeEach(async (to, from, next) => {
    const isSpaAttempt = from.matched.length > 0;
    const { fullPath, path } = to;
    if (isBrowser) {
      const ieVersion = detectIE();
      if (ieVersion && ieVersion < 12 && isSpaAttempt) {
        debug('attempted SPA navigation with a version of IE that cannot detect redirects!');
        debug('abandoning SPA behavior so non-trivial redirects can be followed');
        window.location.assign(fullPath);
        return;
      }
    }
    if (path === from.path && to.name === from.name && isSpaAttempt) {
      if (qs.stringify(to.query) !== qs.stringify(from.query) || to.hash !== from.hash) {
        next();
        return;
      }
      debug('attempting to navigate to the same page, aborting navigation');
      return;
    }
    sendProductListViewedEvent();
    next();
  });

  router.beforeResolve((to, from, next) => {
    const fromName = from.matched[0]?.components?.default.name;
    const toName = to.matched[0]?.components?.default.name;
    const { requiresBeforeUpdateDelegation } = to.meta;
    const delegationConditions = [
      fromName,
      requiresBeforeUpdateDelegation,
      toName === fromName,
      to.path !== from.path,
    ];
    const mustDelegate = delegationConditions.every(Boolean);
    if (mustDelegate && eventBus) {
      eventBus.emit(toName, { to, from, next });
    } else {
      next();
    }
  });

  router.afterEach((to, from) => {
    if (isBrowser) {
      if (to.meta.gtagPageType === 'CLP') {
        localStorage.removeItem('listMetadata');
      } else if (
        from?.meta.gtagPageType !== 'CLP' &&
        ['Search', 'PLP'].includes(to.meta.gtagPageType as string)
      ) {
        localStorage.removeItem('listMetadata');
      }
    }

    if (from?.meta.removeAfterNavigation && from?.name) {
      debug('Removing resolved route', from.name);
      router.removeRoute(from.name);
    }
  });

  if (isBrowser) {
    installPageEvents(router);
  }

  return router;
};

export default {};
