<script setup lang="ts">
import { money } from '@nuts/auto-delivery-sdk/dist/utils/format';
import { onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import { getPredictedShippingCutoff, ShippingCutoff } from '@/api/predictedShipping';
import Modal from '@/components/base/layout/Modal.vue';
import CloseModalButton from '@/components/base/layout/modal/CloseModalButton.vue';
import PredictedShippingCutoffText from '@/components/base/PredictedShippingCutoffText.vue';
import BaseBodyText from '@/components/base/typography/BaseBodyText.vue';
import Header5 from '@/components/base/typography/Header5.vue';
import LargeBodyText from '@/components/base/typography/LargeBodyText.vue';
import ActionBlock from '@/components/cart/added-modal/ActionBlock.vue';
import LineItem from '@/components/cart/added-modal/LineItem.vue';
import ShippingMessage from '@/components/cart/ShippingMessage.vue';
import ProductAddedBanner from '@/components/product-added/ProductAddedBanner.vue';
import ProductAddedContainer from '@/components/product-added/ProductAddedContainer.vue';
import ProductAddedHeader from '@/components/product-added/ProductAddedHeader.vue';
import ProductAddedRecommendations from '@/components/product-added/ProductAddedRecommendations.vue';
import { useCurrentRoute } from '@/composables/navigation/useCurrentRoute';
import { useCallback } from '@/composables/useCallback';
import { useCart } from '@/composables/useCart';
import { useDelivery } from '@/composables/useDelivery';
import { usePayment } from '@/composables/usePayment';
import { NutsLineItem } from '@/lib/cart/lineItem';
import { useInitialRequest } from '@/stores/initialRequest';
import { formatPurchaseItem, gtag } from '@/utils/analytics';

const props = defineProps<{
  addOns?: NutsLineItem[];
  isOpen: boolean;
  lineItem: NutsLineItem;
}>();

const emit = defineEmits(['handle-close']);

const route = useRoute();
const store = useStore();

const { loadLineItemExpansions, lineItems } = useCart(store);
const { path } = useCurrentRoute(useInitialRequest().url, route);
const { freeShippingThreshold } = useDelivery(store);
const { estimatedAmountToCharge, estimatedTotal } = usePayment(store, ref(false));

const loadExpansions = useCallback(async () => {
  await loadLineItemExpansions();
});

const shippingCutoff = ref<ShippingCutoff>();

const refreshContent = async () => {
  await loadExpansions.execute();
  shippingCutoff.value = await getPredictedShippingCutoff();
  if (typeof window !== 'undefined') {
    window.globalShippingCutoff = shippingCutoff.value;
  }
};

onMounted(() => {
  refreshContent();
  gtag('event', 'view_cart', {
    location: path.value,
    currency: 'USD',
    value: money(estimatedAmountToCharge?.value),
    items: lineItems.value.map(formatPurchaseItem),
  });
});
watch(() => props.lineItem.id, refreshContent);

const handleClose = () => {
  emit('handle-close');
};
</script>

<template>
  <Modal
    anchor="right"
    aria-label="cart added modal"
    data-test="cart-added-modal"
    borderRadius="rounded-none"
    :hasControls="false"
    :hasDefaultPadding="false"
    :isOpen
    width="w-full md:max-w-xl"
    @handle-close="handleClose"
  >
    <template #close-button>
      <CloseModalButton
        class="absolute right-5"
        :class="shippingCutoff ? 'top-14' : 'top-5'"
        data-promo="1"
        data-promo-creative="Add to cart modal"
        data-promo-name="Closed by X"
        @click="handleClose"
      />
    </template>
    <template #body>
      <ProductAddedContainer v-if="lineItem" data-test="modal-content">
        <template #header>
          <ProductAddedBanner v-if="shippingCutoff" data-test="predicted-shipping-message">
            <PredictedShippingCutoffText :shippingCutoff />
          </ProductAddedBanner>
          <ProductAddedHeader theme="green">Added to Cart</ProductAddedHeader>
        </template>

        <template #body>
          <!-- Image, quantity and price -->
          <LineItem
            :lineItemWithQuantitySubset="lineItem"
            :loadExpansionsPending="loadExpansions.isPending"
            :showProductName="!!addOns?.length"
            class="mt-4"
            @handle-close="handleClose"
          />

          <template v-if="addOns?.length">
            <LineItem
              v-for="addOn in addOns"
              :key="addOn.id"
              :lineItemWithQuantitySubset="addOn"
              :loadExpansionsPending="loadExpansions.isPending"
              showProductName
              class="mt-4"
              @handle-close="handleClose"
            />
          </template>

          <ShippingMessage
            class="mt-4"
            :freeShippingThreshold
            stackVertically
            :totalPrice="estimatedTotal"
          />

          <div class="relative p-4 bg-white md:hidden">
            <ActionBlock @handle-close="handleClose" />
          </div>

          <ProductAddedRecommendations
            :dyPageContext="{
              type: 'CART',
              data: [`0${lineItem.variant.sku}`],
            }"
            dySelector="[CONFIG] Cart Added - Rows"
            @handle-close="handleClose"
          />
        </template>

        <template #footer>
          <!-- Footer & CTAs -->
          <div class="flex items-center justify-between" data-test="estimated-total">
            <div class="flex items-center">
              <Header5>Estimated total</Header5>
              <BaseBodyText class="ml-1.5">
                ({{ lineItems.length }} item{{ lineItems.length > 1 ? 's' : '' }})
              </BaseBodyText>
            </div>
            <LargeBodyText>{{ money(estimatedTotal) }}</LargeBodyText>
          </div>
          <ActionBlock class="mt-4" @handle-close="handleClose" />
        </template>
      </ProductAddedContainer>
    </template>
  </Modal>
</template>

<style scoped lang="scss">
:deep(.right-anchor-height) {
  overflow: hidden;
}
</style>
