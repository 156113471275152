<script setup lang="ts">
import {
  breakpointsTailwind,
  onClickOutside,
  useBreakpoints,
  useElementVisibility,
  useFocusWithin,
  useStorage,
  useVModel,
  whenever,
} from '@vueuse/core';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import { computed, nextTick, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import CloseIcon from '@/components/base/assets/CloseIcon.vue';
import SearchIcon from '@/components/base/assets/SearchIcon.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import UnstyledButton from '@/components/base/UnstyledButton.vue';
import SearchSuggestionsPopover from '@/components/layout/header/SearchSuggestionsPopover.vue';
import { useRouteChange } from '@/composables/navigation/useRouteChange';
import { useFeatureFlags } from '@/composables/useFeatureFlags';

const props = defineProps<{
  isOpen: boolean;
  query: string;
}>();
const emit = defineEmits<{
  'update:query': [string];
  'update:isOpen': [boolean];
}>();

const route = useRoute();
const router = useRouter();
const store = useStore();

const { navigateTo } = useRouteChange(router);
const searchFormRef = ref<HTMLElement>();
const searchInputRef = ref<HTMLElement>();
const searchSuggestionsRef = ref<HTMLElement>();
const controlSearchSuggestionsRef = ref<HTMLElement>();

const queryModel = useVModel(props, 'query', emit);
const isOpenModel = useVModel(props, 'isOpen', emit);

const isDesktop = useBreakpoints(breakpointsTailwind).greaterOrEqual('lg');
const searchInputVisible = useElementVisibility(searchInputRef);
const searchSuggestionsVisible = useElementVisibility(searchSuggestionsRef);
const { flags, loadDyFlags } = useFeatureFlags(store);
const recentSearches = useStorage<string[]>('recentSearches', []);

const { focused: formFocused } = useFocusWithin(searchFormRef);
const { activate, hasFocus: focusTrapActive } = useFocusTrap(controlSearchSuggestionsRef.value, {
  clickOutsideDeactivates: true,
});

const isSearchPage = computed(() => route?.name === 'Search');
const shouldDisplaySuggestions = computed(
  () => !isSearchPage.value && isOpenModel.value && isDesktop.value,
);

const addRecentSearch = (entry: string) => {
  const existingSearches = recentSearches.value.filter((search) => search !== entry);
  recentSearches.value = [entry, ...existingSearches.slice(0, 4)];
};

const dismissSuggestions = () => {
  isOpenModel.value = false;
};

const handleSearch = async (search?: string) => {
  let href = '/search/instant';
  if (search) {
    addRecentSearch(search);
    href += `?query=${search}`;
  }
  await loadDyFlags('[A/B Test] Search Page Visual Changes', ['searchPageLayoutChanges']);
  navigateTo(href);
};

const handleTab = (event: KeyboardEvent) => {
  if (focusTrapActive.value) return;

  const searchInput = searchInputRef.value;
  const clearButton = searchFormRef.value?.querySelector('#clear-search-query-button');
  const submitButton = searchFormRef.value?.querySelector('button[type="submit"]');

  if (document.activeElement === searchInput) {
    event.preventDefault();
    const button = clearButton ?? submitButton;
    if (button instanceof HTMLButtonElement) {
      button.focus();
    }
  } else if (document.activeElement === submitButton) {
    isOpenModel.value = false;
  }
};

const handleShiftTab = (event: KeyboardEvent) => {
  if (focusTrapActive.value) return;

  const searchInput = searchInputRef.value;
  const button =
    searchFormRef.value?.querySelector('#clear-search-query-button') ??
    searchFormRef.value?.querySelector('button[type="submit"]');

  if (document.activeElement === searchInput) {
    isOpenModel.value = false;
  } else if (document.activeElement === button) {
    event.preventDefault();
    if (searchInput instanceof HTMLInputElement) {
      searchInput.focus();
    }
  }
};

const handleArrowDown = async () => {
  if (focusTrapActive.value) return;
  isOpenModel.value = true;
  await nextTick();
  activate();
};

const handleClearSearch = () => {
  queryModel.value = '';
  searchInputRef.value?.focus();
};

watch(isSearchPage, (isSearch) => {
  if (!isSearch) {
    queryModel.value = '';
  } else {
    dismissSuggestions();
  }
});

watch(() => route?.path, dismissSuggestions);

whenever(formFocused, async () => {
  if (searchInputVisible.value) {
    isOpenModel.value = true;
  }
});

watch(searchSuggestionsVisible, (isVisible) => {
  if (!isVisible) isOpenModel.value = false;
});

onClickOutside(searchFormRef, () => {
  if (shouldDisplaySuggestions.value) dismissSuggestions();
});

const onSuggestionsClosed = (setFocus?: boolean) => {
  isOpenModel.value = false;
  if (setFocus) searchInputRef.value?.focus();
};
</script>

<template>
  <div class="flex flex-col items-center">
    <form
      class="relative w-full"
      ref="searchFormRef"
      role="search"
      @keydown.exact.down="handleArrowDown"
      @keydown.prevent.esc="dismissSuggestions"
      @keydown.tab.exact="handleTab"
      @keydown.tab.shift.exact="handleShiftTab"
      @submit.prevent="handleSearch(queryModel)"
      data-test="header-search-form"
    >
      <input
        v-model="queryModel"
        aria-label="Search. Arrow down for suggestions"
        autocapitalize="off"
        autocomplete="off"
        autocorrect="off"
        class="w-full h-10 px-4 text-base border border-solid rounded-full bg-nuts-stone-100 border-neutral-300 focus:border-black focus:outline-none focus:border-2"
        data-test="header-search-input"
        placeholder="Search for a product"
        ref="searchInputRef"
        spellcheck="false"
        type="search"
        @click="isOpenModel = true"
        @focus="isOpenModel = true"
      />
      <div v-show="shouldDisplaySuggestions" ref="controlSearchSuggestionsRef">
        <SearchSuggestionsPopover
          v-model="isOpenModel"
          :query="query"
          @dismiss="onSuggestionsClosed(true)"
        />
      </div>
      <div class="absolute flex items-center gap-1 right-1 top-1 bottom-1">
        <transition name="fade" :duration="100">
          <UnstyledButton
            v-if="query.length"
            aria-label="Clear search"
            class="flex p-1 rounded-full hover:bg-white"
            data-test="clear-search-query-button"
            id="clear-search-query-button"
            @click="handleClearSearch"
          >
            <CloseIcon :size="16" />
          </UnstyledButton>
        </transition>
        <UnstyledButton
          class="h-full rounded-full bg-nuts-amber-400 hover:bg-amber-400"
          :class="flags.layoutRebrandingChangesV1 ? 'p-1.5' : 'px-4'"
          data-test="header-search-button"
          type="submit"
        >
          <template v-if="flags.layoutRebrandingChangesV1">
            <span class="sr-only">Search</span>
            <SearchIcon :size="20" />
          </template>
          <SmallBodyText v-else class="font-semibold leading-3 text-nuts-neutral-950">
            Search
          </SmallBodyText>
        </UnstyledButton>
      </div>
    </form>
  </div>
</template>
