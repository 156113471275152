<script setup lang="ts">
import dayjs from 'dayjs';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EmailSignupForm from '@/components/base/form/EmailSignupForm.vue';
import Container from '@/components/base/layout/Container.vue';
import BaseBodyText from '@/components/base/typography/BaseBodyText.vue';
import LegalLinks from '@/components/layout/footer/rebrand/LegalLinks.vue';
import NavLinkGroupsSection from '@/components/layout/footer/rebrand/NavLinkGroupsSection.vue';
import SocialLinks from '@/components/layout/footer/rebrand/SocialLinks.vue';
import SubscribeForm from '@/components/layout/footer/rebrand/SubscribeForm.vue';
import WarrantyPromise from '@/components/layout/footer/rebrand/WarrantyPromise.vue';
import { useRouterLinks } from '@/composables/navigation/useRouterLinks';
import { useFeatureFlags } from '@/composables/useFeatureFlags';
import { useReferralElement } from '@/composables/useReferralElement';

const store = useStore();
const { flags } = useFeatureFlags(store);
const referralLink = ref<HTMLElement>();

const currentYear = computed(() => dayjs().format('YYYY'));

const { showReferralElement } = useReferralElement(store, referralLink, 'global_footer');

const templateRoot = ref<HTMLElement>();
useRouterLinks(templateRoot, useRouter());
</script>

<template>
  <div ref="templateRoot">
    <div
      :class="{ 'ptn-footer': !flags.layoutRebrandingChangesV1 }"
      itemscope
      itemtype="http://schema.org/Corporation"
      data-test="ptn-footer"
    >
      <meta itemprop="name" content="Nuts.com" />
      <meta itemprop="brand" content="Nuts.com" />
      <meta itemprop="url" content="https://nuts.com" />
      <footer v-if="flags.layoutRebrandingChangesV1" class="bg-nuts-neutral-950" data-test="footer">
        <Container class="py-8">
          <a href="/" data-test="footer-logo" title="Nuts.com">
            <picture>
              <source
                media="(min-width: 1024px)"
                height="72"
                itemprop="logo"
                loading="lazy"
                width="72"
                srcset="@/assets/layout-rebrand-v1/nuts-stamp-logo.svg"
              />
              <img
                alt="Nuts.com"
                height="64"
                itemprop="logo"
                loading="lazy"
                width="64"
                src="@/assets/layout-rebrand-v1/nuts-stamp-logo.svg"
              />
            </picture>
          </a>
          <WarrantyPromise class="visible block mt-6 xl:hidden" />
          <div class="grid grid-cols-2 gap-8 md:grid-cols-5 xl:grid-cols-7 xl:gap-14 xl:mt-6">
            <WarrantyPromise class="hidden col-span-2 xl:visible xl:block" />
            <NavLinkGroupsSection />
            <div class="grid gap-3 col-span-full md:col-span-2">
              <BaseBodyText class="font-semibold text-stone-200">
                Be the first to know about new products, promos, &amp; exclusive offers
              </BaseBodyText>
              <SubscribeForm />
              <SocialLinks class="flex gap-4 mx-auto my-0 lg:ml-0" data-test="social-services" />
            </div>
          </div>
          <hr class="h-px m-0 my-8 opacity-50 bg-neutral-300" />
          <LegalLinks class="grid gap-4 xl:flex" />
        </Container>
      </footer>
      <footer
        v-else
        id="trk-footer"
        class="container clearfix"
        data-test="footer"
        aria-label="Footer"
      >
        <div class="logo" data-test="footer-logo" ref="templateRoot">
          <a href="/">
            <img src="@/assets/nuts-logo.svg" alt="Nuts.com" itemprop="logo" loading="lazy" />
          </a>
        </div>
        <ul class="services">
          <li>
            <a href="/subscribe" class="email" aria-label="subscribe-link">
              <i />
            </a>
            <span class="email-inner" data-test="email-inner">
              <b>Sign up for our newsletter</b>
              Receive limited, email-only offers!
              <EmailSignupForm section="footer" />
            </span>
          </li>
          <li>
            <span class="referral" data-test="referral">
              <i />
              <span> <b>We love to socialize</b> Come join us on social media! </span>
            </span>
          </li>
          <li>
            <ul class="clearfix social-services" data-test="social-services">
              <li>
                <a
                  class="social-icon-youtube"
                  itemprop="sameAs"
                  href="https://www.youtube.com/nutsdotcom"
                  title="Follow Nuts.com on YouTube"
                  target="_blank"
                  data-test="social-icon-youtube"
                >
                  <img src="@/assets/social-icon-youtube@1x.png" alt="Follow Nuts.com on YouTube" />
                  <span>Follow us on YouTube</span>
                </a>
              </li>
              <li>
                <a
                  class="social-icon-pinterest"
                  itemprop="sameAs"
                  href="https://www.pinterest.com/nutsdotcom/pins/"
                  title="Follow Nuts.com on Pinterest"
                  target="_blank"
                  data-test="social-icon-pinterest"
                >
                  <img
                    src="@/assets/social-icon-pinterest@1x.png"
                    alt="Follow Nuts.com on Pinterest"
                  />
                  <span>Follow us on Pinterest</span>
                </a>
              </li>
              <li>
                <a
                  class="social-icon-facebook"
                  itemprop="sameAs"
                  href="https://www.facebook.com/NutsDotCom/"
                  title="Find Nuts.com on Facebook"
                  target="_blank"
                  data-test="social-icon-facebook"
                >
                  <img src="@/assets/social-icon-facebook@1x.png" alt="Find Nuts.com on Facebook" />
                  <span>Follow us on Facebook</span>
                </a>
              </li>
              <li>
                <a
                  class="social-icon-twitter"
                  itemprop="sameAs"
                  href="https://twitter.com/NutsDotCom"
                  title="Follow Nuts.com on Twitter"
                  target="_blank"
                  data-test="social-icon-twitter"
                >
                  <img src="@/assets/social-icon-twitter@1x.png" alt="Follow Nuts.com on Twitter" />
                  <span>Follow us on Twitter</span>
                </a>
              </li>
              <li>
                <a
                  class="social-icon-instagram"
                  itemprop="sameAs"
                  href="https://www.instagram.com/nutsdotcom/"
                  title="Follow Nuts.com on Instagram"
                  target="_blank"
                  data-test="social-icon-instagram"
                >
                  <img
                    src="@/assets/social-icon-instagram@1x.png"
                    alt="Follow Nuts.com on Instagram"
                  />
                  <span>Follow us on Instagram</span>
                </a>
              </li>
              <li>
                <a
                  class="social-icon-linkedin"
                  itemprop="sameAs"
                  href="https://www.linkedin.com/company/nutsdotcom"
                  title="Follow Nuts.com on LinkedIn"
                  target="_blank"
                  data-test="social-icon-linkedin"
                >
                  <img
                    src="@/assets/social-icon-linkedin@1x.png"
                    alt="Follow Nuts.com on LinkedIn"
                  />
                  <span>Follow us on LinkedIn</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
        <hr class="clearfix" />
        <div
          class="guarantee"
          itemscope
          itemtype="http://schema.org/WarrantyPromise"
          data-test="guarantee"
        >
          <div class="inner">
            <div class="character" data-test="character">
              &nbsp;
              <span>&nbsp;</span>
            </div>
            <h2>
              <b>100%</b>
              <span>
                Committed to
                <br />Our Customers
              </span>
            </h2>
            <div
              itemprop="description"
              class="guarantee-description"
              data-test="guarantee-description"
            >
              <p>
                At Nuts.com, customer satisfaction is our top priority. If you experience a problem
                with our products, customer service, or shipping, please let us know.
              </p>
              <p>
                <b>We’ll do whatever it takes to make it right. It’s a family tradition.</b>
              </p>
            </div>
          </div>
        </div>
        <div class="trust-badges" data-test="trust-badges">
          <div class="digicert" />
        </div>
        <div class="copyright" id="copyright" data-test="copyright">
          &copy; Copyright 1999-{{ currentYear }} Nuts.com
        </div>
      </footer>
    </div>
    <div v-if="!flags.layoutRebrandingChangesV1" class="ptn-bottom-shelf" role="region">
      <div class="container clearfix">
        <ul id="trk-bottom-shelf" data-test="bottom-shelf">
          <li>
            <a href="/about-us">About us</a>
          </li>
          <li>
            <a href="/contact">Contact us</a>
          </li>
          <li v-if="showReferralElement">
            <a v-once href="/refer" ref="referralLink" data-test="refer-and-earn-footer"
              >Refer and Earn</a
            >
          </li>
          <li>
            <a href="/account">Your account</a>
          </li>
          <li>
            <a href="/account/easy-reorder">Easy reorder</a>
          </li>
          <li>
            <a href="/shipping">Shipping</a>
          </li>
          <li>
            <a href="/testimonials">Testimonials</a>
          </li>
          <li>
            <a href="/wholesale/">Wholesale</a>
          </li>
          <li>
            <a href="/media">Media</a>
          </li>
          <li>
            <a href="https://help.nuts.com">Help</a>
          </li>
          <li>
            <a href="/careers">Careers</a>
          </li>
          <li>
            <a href="/privacy">Privacy policy</a>
          </li>
          <li>
            <a href="/california-privacy-notice">CA privacy notice</a>
          </li>
          <li>
            <a href="/terms-and-conditions">T & C</a>
          </li>
          <li>
            <a href="/ccpa-opt-out">Do Not Sell or Share My Personal Information</a>
          </li>
          <li>
            <a href="/opt-out-of-use-sharing-of-sensitive-information-form">
              Limit the Use of My Sensitive Personal Information
            </a>
          </li>
          <li>
            <a href="/accessibility.html">Accessibility</a>
          </li>
          <li>
            <a href="/sitemap">Sitemap</a>
          </li>
        </ul>
      </div>
    </div>

    <div id="dy-bottom-drawer" />
  </div>
</template>
