<script setup lang="ts">
import { useAnnouncer } from '@vue-a11y/announcer';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import Modal from '@/components/base/layout/Modal.vue';
import SignInForm from '@/components/login/SignInForm.vue';
import SignUpForm from '@/components/login/SignUpForm.vue';
import { UseCallback, useCallback } from '@/composables/useCallback';
import { useCart } from '@/composables/useCart';
import { USER_EMAIL_COOKIE } from '@/lib/personalization/common';
import { useAutoDelivery } from '@/stores/autoDelivery';
import { getCookie } from '@/utils/isomorphic/cookie';

type Step = 'sign-in' | 'sign-up';

interface Props {
  callback?: UseCallback;
  destination?: string;
  initialStep?: Step;
  isCheckout?: boolean;
  selectBusiness?: boolean;
  isOpen: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  initialStep: 'sign-in',
});

const emit = defineEmits<{ 'handle-close': [] }>();

const store = useStore();
const router = useRouter();

const { polite } = useAnnouncer();

const currentStep = ref<Step>(props.initialStep);
const email = ref(getCookie(USER_EMAIL_COOKIE, false) ?? '');

const handleClose = () => emit('handle-close');

const { loadLineItemExpansions, removeGreetingCards } = useCart(store);
const signInCallback = useCallback(async (options?: { isBusinessAccount: boolean }) => {
  polite('You have successfully signed in.');
  useAutoDelivery().initSdkClient();
  await Promise.all([loadLineItemExpansions(), removeGreetingCards()]);

  const isSpa = !!router;
  const navigateFn = isSpa ? router.push : window.location.assign;

  if (props.destination && !options?.isBusinessAccount) {
    return navigateFn(props.destination);
  }
  if (props.callback) {
    await props.callback.execute();
  }

  if (options?.isBusinessAccount && !props.isCheckout) {
    sessionStorage.setItem('B2BAccountSource', 'Account Creation');
    return navigateFn('/business/register');
  }

  handleClose();

  return undefined;
});

const onChangeEmail = (step: Step, newEmail?: string) => {
  email.value = newEmail ?? '';
  currentStep.value = step;
};
</script>

<template>
  <Modal
    aria-labelledby="login-modal-label"
    date-test="login-modal"
    anchor="mobile-bottom"
    borderRadius="rounded-t-xl sm:rounded-xl"
    :class="{ 'pointer-events-none': signInCallback.isPending }"
    :hasControls="false"
    :isOpen="isOpen"
    skipFirstElementOnFocus
    width="w-full sm:max-w-md"
    @handle-close="handleClose"
  >
    <template #body>
      <transition name="fade" mode="out-in">
        <SignInForm
          v-if="currentStep === 'sign-in'"
          :email="email"
          :isCheckout="isCheckout"
          :submitCallback="signInCallback"
          @change-email="(email) => onChangeEmail('sign-up', email)"
        >
          <template #header="{ title }">
            <span
              class="text-xl font-bold sm:text-2xl font-sofia-pro"
              id="login-modal-label"
              data-test="login-modal-title"
            >
              {{ title }}
            </span>
          </template>
        </SignInForm>
        <SignUpForm
          v-else
          :email="email"
          :isCheckout="isCheckout"
          :selectBusiness="selectBusiness"
          @signed-in="signInCallback.execute"
          @change-email="(email) => onChangeEmail('sign-in', email)"
        >
          <template #header="{ title }">
            <span class="text-xl font-bold sm:text-2xl font-sofia-pro" id="login-modal-label">
              {{ title }}
            </span>
          </template>
        </SignUpForm>
      </transition>
    </template>
  </Modal>
</template>
