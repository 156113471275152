<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';
import { useStore } from 'vuex';

import { findExistingCustomer, SignInOptions } from '@/api/customer';
import EmailSentConfirmation from '@/components/login/EmailSentConfirmation.vue';
import EmailStep from '@/components/login/EmailStep.vue';
import ErrorDisplay from '@/components/login/ErrorDisplay.vue';
import PasswordStep from '@/components/login/PasswordStep.vue';
import { useAuth } from '@/composables/useAuth';
import { UseCallback, useCallback } from '@/composables/useCallback';
import { TypographyTags } from '@/utils/accessibility';

type Steps = 'email' | 'password' | 'sign-in-link' | 'forgot-password' | 'error-display';

export default defineComponent({
  name: 'SignInForm',
  props: {
    email: { required: false, type: String },
    headerTag: { required: false, type: String as PropType<TypographyTags>, default: 'span' },
    isCheckout: { required: false, type: Boolean, default: false },
    submitCallback: { required: true, type: Object as PropType<UseCallback> },
  },
  components: {
    EmailSentConfirmation,
    EmailStep,
    ErrorDisplay,
    PasswordStep,
  },
  emits: {
    'change-email': (_: string) => true,
  },
  setup(props, { emit }) {
    const store = useStore();

    const { signIn } = useAuth(store);

    const userEmail = ref(props.email || '');
    const password = ref('');

    const currentStep = ref<Steps>('email');
    const user = ref<{ firstName?: string; guest: boolean }>();
    const inTransition = ref(false);

    const setStep = (step: Steps) => {
      inTransition.value = true;
      currentStep.value = step;
    };

    const changeEmail = (emailValue: string) => emit('change-email', emailValue);

    const verifyEmail = useCallback(async (captchaResponse: string) => {
      const customer = await findExistingCustomer(userEmail.value, captchaResponse);

      if (customer) {
        user.value = customer;
        setStep('password');
      } else {
        changeEmail(userEmail.value);
      }
    });

    const signInCallback = useCallback(async (options: SignInOptions) => {
      await signIn(userEmail.value, password.value, options);
      await props.submitCallback.execute({ isBusinessAccount: false });
    });

    return {
      changeEmail,
      currentStep,
      inTransition,
      password,
      setStep,
      signInCallback,
      user,
      userEmail,
      verifyEmail,
    };
  },
});
</script>

<template>
  <div
    :class="{
      'opacity-50 pointer-events-none': submitCallback.isPending || inTransition,
    }"
  >
    <transition name="fade">
      <div v-if="currentStep === 'email' || currentStep === 'password'">
        <slot name="header" :title="isCheckout ? 'Proceed to Checkout' : 'Sign in'">
          <component
            :is="headerTag"
            class="mb-0 text-xl font-bold leading-6 text-left md:text-2xl md:leading-7 font-sofia-pro"
          >
            {{ isCheckout ? 'Proceed to Checkout' : 'Sign in' }}
          </component>
        </slot>
      </div>
    </transition>
    <transition name="fade" mode="out-in" @enter="inTransition = false">
      <EmailStep
        v-if="currentStep === 'email'"
        v-model="userEmail"
        :isCheckout
        :submitCallback="verifyEmail"
        @change-email="changeEmail"
        @social-sign-in="submitCallback.execute"
      />
      <PasswordStep
        v-else-if="currentStep === 'password'"
        v-model="password"
        :email="userEmail"
        :isCheckout
        :submitCallback="signInCallback"
        :user
        @forgot-password="setStep('forgot-password')"
        @error-display="setStep('error-display')"
        @handle-return="setStep('email')"
        @magic-link="setStep('sign-in-link')"
      />
      <EmailSentConfirmation
        v-else-if="currentStep === 'sign-in-link'"
        :headerTag
        :email="userEmail"
        type="sign-in-link"
        @resend="setStep('password')"
      />
      <EmailSentConfirmation
        v-else-if="currentStep === 'forgot-password'"
        :headerTag
        :email="userEmail"
        type="password-reset"
        @resend="setStep('password')"
      />
      <ErrorDisplay v-else-if="currentStep === 'error-display'" :headerTag />
    </transition>
  </div>
</template>
