/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import createDebug from 'debug';
import https from 'https';
import merge from 'lodash/merge';
import { createStore, ModuleTree } from 'vuex';

import api, { webstore } from '@/api';
import cartModule from '@/store/modules/cart';
import customTraysModule from '@/store/modules/customTrays';
import digitalGiftingModule from '@/store/modules/digitalGifting';
import featureFlagsModule from '@/store/modules/featureFlags';

const debug = createDebug('nuts:createStore');

export const state = () => ({});

export type RootState = ReturnType<typeof state>;

const modules: ModuleTree<RootState> = {
  cartModule,
  customTraysModule,
  digitalGiftingModule,
  featureFlagsModule,
};

export default (
  sharedState:
    | {
        httpsAgent?: https.Agent;
        webstoreHttpsAgent?: https.Agent;
        authTokenInfo?: any;
      }
    | undefined = undefined,
  initialValues: { moduleStates?: Partial<typeof modules> } & Partial<RootState> = {},
) => {
  if (sharedState) {
    // Note: sharedState can go away once it is safe to reuse the same bundle
    // renderer (and runInNewContext: 'once' or false) across all requests.
    if (!sharedState.httpsAgent) {
      sharedState.httpsAgent = new https.Agent({
        ...https.globalAgent.options,
        keepAlive: true,
      });
    }
    https.globalAgent = sharedState.httpsAgent;
    api.defaults.httpsAgent = sharedState.httpsAgent;

    if (!sharedState.webstoreHttpsAgent) {
      sharedState.webstoreHttpsAgent = new https.Agent({
        ...webstore.defaults.httpsAgent.options,
        keepAlive: true,
      });
    }
    webstore.defaults.httpsAgent = sharedState.webstoreHttpsAgent;

    if (!sharedState.authTokenInfo) {
      sharedState.authTokenInfo = {};
    }
    api.setSharedTokenCache(sharedState.authTokenInfo);
  }

  debug('initialValues: %O', initialValues);
  const { moduleStates, ...stateValues } = initialValues;

  const store = createStore({
    state: () => ({ ...state(), ...stateValues }),
    modules,
    // FIXME: strict: process.env.NODE_ENV === 'development',
  });

  if (moduleStates && Object.entries(moduleStates).length) {
    Object.entries(moduleStates).forEach(([moduleKey, values]) => {
      if (!(moduleKey in modules) || !(moduleKey in store.state)) return;
      merge(store.state[moduleKey as keyof RootState], values);
    });
  }

  return store;
};
