<script setup lang="ts">
import facebookLogo from '@/assets/layout-rebrand-v1/social/facebook.svg';
import instagramLogo from '@/assets/layout-rebrand-v1/social/instagram.svg';
import linkedinLogo from '@/assets/layout-rebrand-v1/social/linkedin.svg';

interface SocialLink {
  dataTest: string;
  description: string;
  href: string;
  imgHeight: number;
  imgWidth: number;
  imgSrc: string;
}

const socialLinks: SocialLink[] = [
  {
    dataTest: 'footer-facebook-link',
    description: 'Find Nuts.com on Facebook',
    href: 'https://www.facebook.com/NutsDotCom/',
    imgSrc: facebookLogo,
    imgHeight: 18,
    imgWidth: 18,
  },
  {
    dataTest: 'footer-instagram-link',
    description: 'Follow Nuts.com on Instagram',
    href: 'https://www.instagram.com/nutsdotcom/',
    imgHeight: 24,
    imgWidth: 24,
    imgSrc: instagramLogo,
  },
  {
    dataTest: 'footer-linkedin-link',
    description: 'Follow Nuts.com on LinkedIn',
    href: 'https://www.linkedin.com/company/nutsdotcom',
    imgHeight: 24,
    imgWidth: 24,
    imgSrc: linkedinLogo,
  },
];
</script>

<template>
  <ul>
    <li v-for="socialLink in socialLinks" :key="socialLink.href">
      <a
        itemprop="sameAs"
        :href="socialLink.href"
        :data-test="socialLink.dataTest"
        :title="socialLink.description"
        target="_blank"
      >
        <img
          :alt="socialLink.description"
          :height="socialLink.imgHeight"
          loading="lazy"
          :width="socialLink.imgWidth"
          :src="socialLink.imgSrc"
        />
      </a>
    </li>
  </ul>
</template>
