import {
  BuilderContent,
  fetchOneEntry,
  getBuilderSearchParams,
  isEditing,
} from '@builder.io/sdk-vue';
import camelCase from 'lodash/camelCase';
import { defineStore, storeToRefs } from 'pinia';
import {
  computed,
  onBeforeMount,
  onMounted,
  onServerPrefetch,
  onUpdated,
  ref,
  UnwrapRef,
  watchEffect,
} from 'vue';
import { LocationQuery, useRoute } from 'vue-router';

import { getConfigEntry } from '@/api/config';
import { useCurrentRoute } from '@/composables/navigation/useCurrentRoute';
import { useCallback } from '@/composables/useCallback';
import { CONTACT_EMAIL_COOKIE, CUSTOMER_ORDER_COUNT_COOKIE } from '@/lib/personalization/common';
import { useCustomer } from '@/stores/customer';
import { useInitialRequest } from '@/stores/initialRequest';
import { useSession } from '@/stores/session';
import { sendExperimentViewedEvent } from '@/utils/analytics/experimentViewedEvent';
import {
  contentRequiresVariationIdFromCookie,
  getContentVariationInfoFromCookie,
  normalizeQueryParam,
} from '@/utils/cms';
import { getCookie, setCookie } from '@/utils/isomorphic/cookie';

export type ContentModel =
  | 'account-banner'
  | 'category-top-shelf'
  | 'cart-discount-message'
  | 'cross-sell-section'
  | 'customizer-recommendations'
  | 'faq'
  | 'header-announcement-bar'
  | 'header-logo'
  | 'mega-menu'
  | 'mobile-menu'
  | 'page'
  | 'promotional-messaging-bar'
  | 'referral-page'
  | 'site-navigation'
  | 'sitewide-banner'
  | 'symbol';

export function isEditingSSR(query: LocationQuery | URLSearchParams) {
  if (query.constructor === URLSearchParams) {
    return query.has('builder.frameEditing');
  }
  return 'builder.frameEditing' in query;
}

function trackContentAttribution(content: BuilderContent) {
  if (content.testVariationId) {
    sendExperimentViewedEvent(
      {
        experiment_id: content.id!,
        experiment_name: content.name,
        variation_id: content.testVariationId,
        variation_name: content.testVariationName,
      },
      'Builder.io',
    );
  }
}

export const useCms = <T extends BuilderContent = BuilderContent>(
  model: ContentModel,
  prefetchMethod?: 'criticalData' | 'setup',
) => {
  const route = useRoute();

  const customerStore = useCustomer();
  const { url } = storeToRefs(useInitialRequest());
  const sessionStore = useSession();

  const { path, query } = useCurrentRoute(url.value, route);
  const initialRequestUtmParams = computed<{
    utmCampaign?: string;
    utmContent?: string;
    utmMedium?: string;
    utmSource?: string;
    utmTerm?: string;
  }>(() => {
    const { searchParams } = new URL(url.value);
    return Array.from(searchParams).reduce((utmValues, [param, value]) => {
      if (!param.startsWith('utm')) return utmValues;
      return {
        ...utmValues,
        [camelCase(param)]: value,
      };
    }, {});
  });

  const timeoutMs: number = import.meta.env.SSR
    ? Number(import.meta.env.VITE_DY_SERVER_SIDE_TIMEOUT)
    : Number(import.meta.env.VITE_DY_CLIENT_SIDE_TIMEOUT);

  const fetchCmsContent = useCallback(
    (
      urlPath: string,
      urlQuery?: LocationQuery | URLSearchParams,
    ): Promise<UnwrapRef<T> | null | undefined> => {
      const includeUnpublished = normalizeQueryParam(urlQuery, 'includeUnpublished');
      // @ts-expect-error (`BuilderContent` is not assignable to `T` but it should be)
      return Promise.race([
        fetchOneEntry({
          apiKey: getConfigEntry('builderIo').key,
          model,
          options: {
            ...getBuilderSearchParams(urlQuery as URLSearchParams),
            includeUnpublished: includeUnpublished.split(',').includes(model),
          },
          userAttributes: {
            ...initialRequestUtmParams.value,
            businessIndustry: customerStore.businessIndustry,
            hasBusinessAccount: customerStore.hasBusinessAccount,
            hasBusinessIndustry: !!customerStore.businessIndustry,
            isB2BContact: customerStore.contact?.isB2b ?? false,
            isContact: !!getCookie(CONTACT_EMAIL_COOKIE, false),
            isCustomer: !!getCookie(CUSTOMER_ORDER_COUNT_COOKIE),
            newSession: sessionStore.newDySession,
            pageType: route?.meta.dyPageType ?? 'OTHER',
            urlPath,
            userHasBusinessAccount: sessionStore.userHasBusinessAccount ?? false,
          },
        }),
        new Promise<null>((resolve, reject) => {
          setTimeout(() => reject(new Error(`Race Timeout exceeded ${timeoutMs} ms`)), timeoutMs);
        }),
      ]);
    },
  );

  const cmsStore = defineStore(`cms:${model}`, {
    state: () => ({
      fetchCmsContent,
      prefetchedContent: undefined as T | null | undefined,
      prefetchedContentTestDecision: undefined as string | undefined,
    }),
  })();

  // can be lost in hydration
  if (!('execute' in cmsStore.fetchCmsContent)) {
    // @ts-expect-error (TS thinks there's another UnwrapRef layer)
    cmsStore.fetchCmsContent = fetchCmsContent;
  }

  const content = ref<T | UnwrapRef<T> | null | undefined>(cmsStore.prefetchedContent);

  const contentLoadedEvent = computed<CustomEvent<typeof route | URL>>(
    () =>
      new CustomEvent(`onContentLoaded:${model}`, {
        detail: route ?? new URL(url.value),
      }),
  );

  const loading = ref(false);

  const loadCmsContent = async (
    urlPath: string = path.value,
    urlQuery: LocationQuery | URLSearchParams = query.value,
  ) => {
    loading.value = true;
    content.value = await cmsStore.fetchCmsContent.execute(urlPath, urlQuery);
    loading.value = false;
    if (content.value) {
      if (contentRequiresVariationIdFromCookie(content.value) === false) {
        trackContentAttribution(content.value);
      }
    }
    cmsStore.fetchCmsContent.result = undefined; // unset to avoid stale values
    if (prefetchMethod) {
      cmsStore.prefetchedContent = content.value;
    }
  };

  if (prefetchMethod === 'setup') {
    onServerPrefetch(async () => {
      if (isEditingSSR(query.value)) return; // do not SSR for visual editor; currently crashes during hydration
      if (content.value || content.value === null) return; // already set by `criticalData()`
      await loadCmsContent();
    });

    onBeforeMount(() => {
      let hydratingPrefetchedContent = path.value === content.value?.data?.url;
      if (!hydratingPrefetchedContent && path.value.endsWith('/')) {
        hydratingPrefetchedContent = path.value.slice(0, -1) === content.value?.data?.url;
      }

      if (cmsStore.prefetchedContentTestDecision) {
        const cookieKey = `builder.tests.${content.value?.id}`;
        if (!getCookie(cookieKey, false)) {
          setCookie(cookieKey, cmsStore.prefetchedContentTestDecision);
        }
        hydratingPrefetchedContent = true;
        cmsStore.prefetchedContentTestDecision = undefined;
      }

      cmsStore.prefetchedContent = undefined;

      if (hydratingPrefetchedContent && contentRequiresVariationIdFromCookie(content.value)) {
        trackContentAttribution({
          ...content.value,
          ...getContentVariationInfoFromCookie(content.value),
        });
      }

      watchEffect(() => {
        if (cmsStore.prefetchedContent?.data?.url !== content.value?.data?.url) {
          if (hydratingPrefetchedContent) return;
          content.value = cmsStore.prefetchedContent;
        }
      });
      hydratingPrefetchedContent = false;
    });

    const raiseEvent = () => document.dispatchEvent(contentLoadedEvent.value);
    onMounted(raiseEvent);
    onUpdated(raiseEvent);
  }

  if (prefetchMethod !== 'criticalData') {
    onMounted(async () => {
      if (content.value === undefined || isEditing()) {
        await loadCmsContent();
      }
    });
  }

  watchEffect(() => {
    if (cmsStore.fetchCmsContent.error) {
      cmsStore.fetchCmsContent.result = undefined;
      content.value = undefined;
    }
  });

  return {
    commitTestDecision: (testVariationId: string) => {
      cmsStore.prefetchedContentTestDecision = testVariationId;
    },
    content: computed(() => ({
      error: cmsStore.fetchCmsContent.error,
      loading: loading.value,
      result: content.value,
    })),
    loadCmsContent,
  };
};
