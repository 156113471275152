<script setup lang="ts">
import { email as validEmail, required } from '@vee-validate/rules';
import { Form } from 'vee-validate';
import { computed, reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';

import { WebstoreSignUpCustomer, WebstoreSignUpOptions } from '@/api/customer';
import CheckIcon from '@/components/base/assets/CheckIcon.vue';
import CloseIcon from '@/components/base/assets/CloseIcon.vue';
import Checkbox from '@/components/base/form/Checkbox.vue';
import EmailTypoSuggestion from '@/components/base/form/EmailTypoSuggestion.vue';
import FormInput, { InputAttributes } from '@/components/base/form/FormInput.vue';
import Radio from '@/components/base/form/Radio.vue';
import Collapse from '@/components/base/layout/Collapse.vue';
import RouteLink from '@/components/base/RouteLink.vue';
import SeparatorWithText from '@/components/base/SeparatorWithText.vue';
import ThemedButton from '@/components/base/ThemedButton.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import EmailDisplay from '@/components/login/EmailDisplay.vue';
import AppleSignInButton from '@/components/social/AppleSignInButton.vue';
import Captcha, { CaptchaPublicInstance } from '@/components/social/Captcha.vue';
import FacebookSignInButton from '@/components/social/FacebookSignInButton.vue';
import GoogleSignInButton from '@/components/social/GoogleSignInButton.vue';
import { useAuth } from '@/composables/useAuth';
import { useCallback } from '@/composables/useCallback';
import { FlexibleMessageValidator, useForm } from '@/composables/useForm';
import { TypographyTags } from '@/utils/accessibility';

type SignUpData = Omit<WebstoreSignUpCustomer, 'isB2b'>;

const props = withDefaults(
  defineProps<{
    email?: string;
    headerTag?: TypographyTags;
    isCheckout?: boolean;
    selectBusiness?: boolean;
  }>(),
  {
    headerTag: 'span',
  },
);

const emit = defineEmits(['change-email', 'password-visibility', 'signed-in']);
const store = useStore();
const { signUp } = useAuth(store);
const { errorMessages, validatorFailed } = useForm();

const captcha = ref<CaptchaPublicInstance>();
const isCaptchaPending = ref(false);
const captchaResponse = ref('');

const showPassword = ref(false);
const user = ref<WebstoreSignUpCustomer>({
  email: props.email ?? '',
  isB2b: props.selectBusiness,
  name: '',
  password: '',
});
const options = ref<Required<WebstoreSignUpOptions>>({
  keepSignedIn: true,
  optInNewsletter: true,
  preserveCart: false,
  attachGuestOrders: true,
});

const formInputAttributes = computed<Record<keyof SignUpData, InputAttributes>>(() => ({
  email: {
    autocomplete: 'username',
    name: 'email',
    placeholder: 'Email',
    type: 'email',
  },
  name: {
    autocomplete: 'name',
    name: 'name',
    placeholder: 'Full Name',
    type: 'text',
  },
  password: {
    autocomplete: 'current-password',
    name: 'password',
    placeholder: 'Password',
    type: showPassword.value ? 'text' : 'password',
  },
}));
const showGoogleSignIn = computed(() => {
  if (typeof window !== 'undefined') {
    return !window?.navigator.userAgent.includes('Instagram');
  }
  return true;
});

interface PasswordValidator {
  message: string;
  status: boolean;
  validate: (value: string) => boolean;
}

const passwordValidators: PasswordValidator[] = reactive([
  {
    message: 'At least 8 characters in length',
    status: false,
    validate: (value: string) => value.length >= 8,
  },
  {
    message: 'A upper case letter',
    status: false,
    validate: (value: string) => /[A-Z]+/.test(value),
  },
  {
    message: 'A lower case letter',
    status: false,
    validate: (value: string) => /[a-z]+/.test(value),
  },
  {
    message: 'A special character (!@./#$%~!`^&*-+=?)',
    status: false,
    validate: (value: string) => /[!"@./#$%&'()*+,\-./:;<=>?@[\]^_`{|}~]+/.test(value),
  },
]);

const rules: Record<keyof SignUpData, FlexibleMessageValidator<string>> = {
  email: (value) => {
    if (props.email) return true;
    if (!required(value)) return errorMessages.email.required;
    if (!validEmail(value)) return errorMessages.email.email;
    return true;
  },
  name: (value) => required(value) || errorMessages.name.required,
  password: (value) => {
    // `password` is called with `undefined` when field is blurred, but we want to persist
    // the current error state in that case, so ignore if `undefined`.
    if (value !== undefined) {
      passwordValidators.forEach((passwordValidator, index) => {
        const isValid = passwordValidator.validate(value);
        // eslint-disable-next-line no-param-reassign
        passwordValidator.status = isValid;
      });
    }

    return passwordValidators.every((passwordValidator) => passwordValidator.status) || 'error';
  },
};

const showPasswordValidators = ref(false);
const showPasswordValidatorIcons = ref(false);
watch(
  () => user.value.password,
  (value) => {
    if (value.length > 0) showPasswordValidatorIcons.value = true;
  },
  { once: true },
);

const toggleShowPassword = () => {
  showPassword.value = !showPassword.value;
  emit('password-visibility', { visible: showPassword.value });
};

const onSignIn = () => emit('signed-in', { isBusinessAccount: user.value.isB2b });

const signUserUp = useCallback(async (response: string) => {
  if (!response) return;
  captchaResponse.value = response;

  const customer = await signUp(user.value, captchaResponse.value);
  if (customer) onSignIn();
});

const onSubmit = () => {
  if (captchaResponse.value) {
    captcha.value?.reset();
    captchaResponse.value = '';
  }
  captcha.value?.execute();
};

const isLoading = computed(() => isCaptchaPending.value || signUserUp.isPending);

const changeEmail = () => emit('change-email');
const hidePasswordSvg = nutshell['img/hide_password.svg'];
const showPasswordSvg = nutshell['img/show_password.svg'];
</script>

<template>
  <div :class="{ 'opacity-50 pointer-events-none': isLoading }">
    <transition name="fade">
      <slot name="header" :title="isCheckout ? 'Proceed to Checkout' : 'Sign up'">
        <component :is="headerTag" class="mb-0 text-xl font-bold sm:text-2xl font-sofia-pro">
          {{ isCheckout ? 'Proceed to Checkout' : 'Sign up' }}
        </component>
      </slot>
    </transition>
    <transition mode="out-in" name="fade">
      <div>
        <p v-if="email" aria-live="polite" class="mt-6 text-xs font-semibold sm:text-base">
          Looks like you don't have an account with us, follow the steps below to create an account.
        </p>
        <div class="flex w-full mt-6 space-between" aria-label="Account Type" role="radiogroup">
          <Radio
            v-model="user.isB2b"
            aria-label="Personal"
            data-test="personal-account-option"
            class="mr-6 text-sm font-normal sm:text-base"
            name="account-type"
            :value="false"
          >
            Personal Account
          </Radio>
          <Radio
            v-model="user.isB2b"
            aria-label="Business"
            data-test="business-account-option"
            class="text-sm font-normal sm:text-base"
            name="account-type"
            :value="true"
          >
            Business Account
          </Radio>
        </div>
        <SeparatorWithText class="mt-5" data-test="sign-up-with-email"
          >Sign up with email</SeparatorWithText
        >
        <div :class="{ 'opacity-50 pointer-events-none': isLoading }">
          <Form v-slot="{ meta: formMeta }" @submit="onSubmit">
            <fieldset>
              <transition name="fade">
                <div
                  v-if="signUserUp.error"
                  v-html="signUserUp.error.message"
                  class="mt-4 font-semibold md:mt-7 text-nuts-red-800"
                />
              </transition>
              <span v-if="!email" class="relative">
                <FormInput
                  v-model="user.email"
                  class="mt-4 md:mt-7"
                  :inputAttributes="formInputAttributes.email"
                  showLabel
                  :validator="rules.email"
                />
                <EmailTypoSuggestion v-model="user.email" />
              </span>
              <EmailDisplay v-else class="mt-5" :email="email" @change-email="changeEmail" />
              <FormInput
                v-model="user.name"
                class="mt-6"
                :inputAttributes="formInputAttributes.name"
                showLabel
                :validator="rules.name"
              />
              <FormInput
                v-model="user.password"
                class="w-full mt-6"
                :inputAttributes="formInputAttributes.password"
                showLabel
                suppressMessaging
                :validator="rules.password"
                @focus.once="showPasswordValidators = true"
              >
                <template #fallbackIcon>
                  <img
                    class="w-4 cursor-pointer opacity-60"
                    alt="hide password"
                    aria-hidden="true"
                    :src="showPassword ? hidePasswordSvg : showPasswordSvg"
                    @click="toggleShowPassword"
                  />
                </template>
              </FormInput>
              <Collapse :expanded="showPasswordValidators" class="mt-2">
                <ul data-test="password-validators" id="inputHelperText-password">
                  <li
                    v-for="(passwordValidator, index) in passwordValidators"
                    :key="passwordValidator.message"
                    class="flex items-center text-sm"
                  >
                    <Transition name="fade">
                      <div v-if="showPasswordValidatorIcons" class="h-4 mr-1">
                        <component
                          :aria-describedby="`requirement-${index}`"
                          :class="passwordValidator.status ? 'text-green-700' : 'text-red-700'"
                          :is="passwordValidator.status ? CheckIcon : CloseIcon"
                          role="img"
                          :size="16"
                        />
                      </div>
                    </Transition>
                    <SmallBodyText class="text-neutral-600" :id="`requirement-${index}`">
                      <span class="sr-only">
                        {{ passwordValidator.status ? 'Requirement met:' : 'Requirement not met:' }}
                      </span>
                      {{ passwordValidator.message }}.
                    </SmallBodyText>
                  </li>
                </ul>
              </Collapse>
              <Checkbox
                v-model="options.keepSignedIn"
                class="inline-flex mt-6 text-sm font-normal sm:text-base"
              >
                Remember me
              </Checkbox>
              <ThemedButton
                class="mt-6 sm:h-12 h-11"
                :disabled="validatorFailed(formMeta) ?? false"
                fullWidth
                :isLoading="isLoading"
                theme="gray"
                type="submit"
                data-test="create-account"
              >
                Create Account
              </ThemedButton>
            </fieldset>
          </Form>
        </div>
        <SeparatorWithText class="mt-5">Sign in or sign up with social account</SeparatorWithText>
        <div class="flex flex-col mt-5 gap-y-4">
          <GoogleSignInButton v-if="showGoogleSignIn" fullWidth @signed-in="onSignIn" />
          <FacebookSignInButton fullWidth @signed-in="onSignIn" />
          <AppleSignInButton fullWidth @signed-in="onSignIn" />
        </div>
        <div class="flex items-center justify-center mt-4">
          <p class="text-base font-semibold">Already have an account?</p>
          <button
            class="p-0 ml-1 bg-transparent border-none cursor-pointer"
            @click="changeEmail"
            data-test="signup-form-sign-in-link"
          >
            <span
              class="text-sm font-semibold underline text-cyan-700 font-proxima-nova sm:text-base"
            >
              Sign In
            </span>
          </button>
        </div>
        <p class="mt-4 text-xs sm:text-sm">
          By clicking on Continue with{{ showGoogleSignIn ? ' Google,' : '' }} Facebook or Apple,
          you represent that you are 18+ years of age and have read and agreed to the Nuts.com
          <RouteLink class="underline" to="/terms-and-conditions">Terms &amp; Conditions</RouteLink
          >,
          <RouteLink class="underline" to="/privacy">Privacy Policy</RouteLink>
          and
          <RouteLink class="underline" to="/california-privacy-notice">CA Privacy Notice</RouteLink
          >. Nuts.com may send you communications. You may change your preferences in your account
          preferences at any time.
        </p>
      </div>
    </transition>
    <Captcha ref="captcha" @verify="signUserUp.execute" />
  </div>
</template>

<style lang="scss" scoped>
p {
  @apply mb-0;
}
</style>
