<script setup lang="ts">
import { email, required } from '@vee-validate/rules';
import { Form } from 'vee-validate';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { subscribe } from '@/api/contacts';
import NutsLogo from '@/components/base/assets/NutsLogo.vue';
import EmailTypoSuggestion from '@/components/base/form/EmailTypoSuggestion.vue';
import FormInput, { InputAttributes } from '@/components/base/form/FormInput.vue';
import Container from '@/components/base/layout/Container.vue';
import ThemedButton from '@/components/base/ThemedButton.vue';
import ToastNotification from '@/components/base/ToastNotification.vue';
import BaseBodyText from '@/components/base/typography/BaseBodyText.vue';
import Header6 from '@/components/base/typography/Header6.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import NavLink from '@/components/layout/footer/rebrand/NavLink.vue';
import { useRouterLinks } from '@/composables/navigation/useRouterLinks';
import { useCallback } from '@/composables/useCallback';
import { useFeatureFlags } from '@/composables/useFeatureFlags';
import { FlexibleMessageValidator, useForm } from '@/composables/useForm';
import { useCustomer } from '@/stores/customer';

const store = useStore();
const { flags } = useFeatureFlags(store);
const { errorMessages, validatorFailed } = useForm();

const subscribed = ref(false);
const isLoading = ref(false);
const isEmailInputFocused = ref(false);
const emailInput = ref(useCustomer().customer?.email ?? '');
const firstNameInput = ref(useCustomer().customer?.firstName ?? '');
const notificationMessage = ref<string>();

const displayFirstNameInput = computed(() => emailInput.value || isEmailInputFocused.value);

const rootElement = ref<HTMLElement>();
useRouterLinks(rootElement, useRouter());

const formInputAttributes: Record<'email' | 'name', InputAttributes> = {
  email: {
    autocomplete: 'email',
    name: 'email',
    placeholder: 'Your Email',
    type: 'email',
  },
  name: {
    name: 'name',
    placeholder: 'Your Name (optional)',
    type: 'text',
  },
};

const rules: Record<'email', FlexibleMessageValidator<string>> = {
  email: (value) => {
    if (!required(value)) return errorMessages.email.required;
    if (!email(value)) return errorMessages.email.email;
    return true;
  },
};

const onSubmit = useCallback(async () => {
  try {
    await subscribe(emailInput.value, firstNameInput.value);
    subscribed.value = true;
    notificationMessage.value =
      'Thank you for subscribing! Stay tuned for exciting updates and exclusive content!';
  } catch (error) {
    notificationMessage.value = 'Oops... Something went wrong. Please try again.';
  }
});
</script>

<template>
  <footer
    v-if="flags.layoutRebrandingChangesV1"
    class="py-8 bg-nuts-neutral-950"
    data-test="footer"
  >
    <Container>
      <div
        class="flex flex-col items-center gap-6 py-8 mx-auto md:gap-10 md:justify-between md:flex-row xl:max-w-screen-2xl lg:gap-0"
        ref="rootElement"
      >
        <a href="/" data-test="footer-logo">
          <picture>
            <source
              media="(min-width: 1024px)"
              height="72"
              itemprop="logo"
              loading="lazy"
              width="72"
              srcset="@/assets/layout-rebrand-v1/nuts-stamp-logo.svg"
            />
            <img
              alt="Nuts.com"
              height="64"
              itemprop="logo"
              loading="lazy"
              width="64"
              src="@/assets/layout-rebrand-v1/nuts-stamp-logo.svg"
            />
          </picture>
        </a>

        <div
          class="flex flex-col gap-3 mt-6 md:mt-0 md:items-center 2xl:max-w-none"
          :class="displayFirstNameInput ? 'md:flex-col lg:flex-row' : 'md:flex-row'"
        >
          <Header6
            class="text-white lg:max-w-[212px] w-72"
            :class="displayFirstNameInput ? 'w-full text-center' : 'md:max-w-[200px] text-start'"
          >
            Subscribe to our newsletter and get exclusive offers!
          </Header6>
          <Form
            v-if="!subscribed"
            v-slot="{ meta: formEmailMeta }"
            class="flex flex-col gap-5 md:gap-3 md:flex-row"
            @submit="onSubmit.execute()"
          >
            <FormInput
              v-model="emailInput"
              aria-label="Email"
              class="w-full"
              :inputAttributes="formInputAttributes.email"
              :validator="rules.email"
              showPlaceholder
              showLabel
              theme="dark"
              @blur="isEmailInputFocused = false"
              @focus="isEmailInputFocused = true"
            />
            <transition name="fade">
              <FormInput
                v-if="displayFirstNameInput"
                v-model="firstNameInput"
                aria-label="Firt name"
                class="w-full"
                :inputAttributes="formInputAttributes.name"
                showLabel
                showPlaceholder
              />
            </transition>
            <ThemedButton
              type="submit"
              size="default"
              theme="dark-yellow"
              class="w-full md:w-min"
              :disabled="(formEmailMeta.touched && validatorFailed(formEmailMeta)) ?? false"
              :isLoading="onSubmit.isPending"
            >
              Subscribe
            </ThemedButton>
          </Form>
        </div>
        <ToastNotification
          v-if="subscribed"
          :show="!!notificationMessage"
          @hide="notificationMessage = ''"
        >
          <SmallBodyText>{{ notificationMessage }}</SmallBodyText>
        </ToastNotification>
      </div>
      <hr class="h-px m-0 bg-neutral-300" />

      <div class="py-4" data-test="footer-bottom-shelf-container">
        <ul class="flex gap-6 place-content-center">
          <li>
            <NavLink class="font-semibold" title="About Us" url="/about-us" />
          </li>
          <li>
            <NavLink class="font-semibold" title="Shipping" url="/shipping" />
          </li>
          <li>
            <NavLink class="font-semibold" title="Help" url="https://help.nuts.com/" />
          </li>
          <li>
            <NavLink class="font-semibold" title="Privacy Policy" url="/privacy" />
          </li>
          <li>
            <NavLink
              class="font-semibold"
              title="CA Privacy Notice"
              url="/california-privacy-notice"
            />
          </li>
          <li>
            <NavLink class="font-semibold" title="Accessibility" url="/accessibility.html" />
          </li>
        </ul>
        <ul class="flex gap-6 mt-2 place-content-center">
          <li>
            <NavLink class="font-semibold" title="T & C" url="/terms-and-conditions" />
          </li>
          <li>
            <NavLink
              class="font-semibold"
              title="Do Not Sell My Personal Information"
              url="/ccpa-opt-out"
            />
          </li>
          <li>
            <NavLink
              class="font-semibold"
              title="Limit the Use of My Sensitive Personal Information"
              url="/opt-out-of-use-sharing-of-sensitive-information-form"
            />
          </li>
        </ul>
      </div>
    </Container>
  </footer>
  <div v-else class="bg-nuts-neutral-950">
    <div
      class="flex flex-col items-center self-stretch p-8 mx-auto md:justify-between md:flex-row lg:px-16 2xl:max-w-screen-2xl"
      ref="rootElement"
    >
      <a href="/" data-test="footer-logo">
        <NutsLogo class="w-24 text-white h-9 lg:w-32 lg:h-12" />
        <span class="sr-only">Nuts.com homepage</span>
      </a>
      <Form
        v-if="!subscribed"
        class="flex flex-col md:flex-row md:items-center"
        @submit="onSubmit.execute()"
        v-slot="{ meta: formEmailMeta }"
      >
        <SmallBodyText
          class="pt-6 text-center text-white md:text-right md:mr-3 md:pt-0 md:w-full force-text-sm"
        >
          Subscribe to our newsletter and get exclusive offers!
        </SmallBodyText>
        <div class="relative w-full h-full">
          <div class="flex gap-2 md:mr-3">
            <FormInput
              v-model="emailInput"
              aria-label="email"
              class="w-full mt-3 md:mt-0"
              :inputAttributes="formInputAttributes.email"
              :validator="rules.email"
              showPlaceholder
              showLabel
            />
            <FormInput
              v-if="emailInput"
              v-model="firstNameInput"
              aria-label="firt name"
              class="lg:w-full"
              :inputAttributes="formInputAttributes.name"
              showLabel
              showPlaceholder
            />
          </div>
          <EmailTypoSuggestion v-model="emailInput" />
        </div>
        <ThemedButton
          type="submit"
          size="large"
          theme="dark-yellow"
          class="w-full mt-4 md:mt-0 md:w-min"
          :disabled="(formEmailMeta.touched && validatorFailed(formEmailMeta)) ?? false"
          :isLoading
        >
          Subscribe
        </ThemedButton>
      </Form>
      <BaseBodyText
        v-else
        class="pt-6 font-semibold text-center text-white md:text-right md:mr-3 md:pt-0 md:w-full"
      >
        Thank you for subscribing!
      </BaseBodyText>
    </div>
    <div class="py-6 border-t border-white border-solid">
      <ul
        class="flex flex-wrap justify-center mx-auto text-center 2xl:max-w-screen-2xl"
        data-test="bottom-shelf"
      >
        <li class="mt-2 mr-6">
          <a class="text-base no-link-styles" href="/about-us">About us</a>
        </li>
        <li class="mt-2 mr-6">
          <a class="text-base no-link-styles" href="/shipping">Shipping</a>
        </li>
        <li class="mt-2 mr-6">
          <a class="text-base no-link-styles" href="https://help.nuts.com">Help</a>
        </li>
        <li class="mt-2 mr-6">
          <a class="text-base no-link-styles" href="/privacy">Privacy policy</a>
        </li>
        <li class="mt-2 mr-6">
          <a class="text-base no-link-styles" href="/california-privacy-notice"
            >CA privacy notice</a
          >
        </li>
        <li class="mt-2 mr-6">
          <a class="text-base no-link-styles" href="/accessibility.html">Accessibility</a>
        </li>
        <li class="mt-2 mr-6">
          <a class="text-base no-link-styles" href="/terms-and-conditions">T & C</a>
        </li>
        <li class="mt-2 mr-6">
          <a class="text-base no-link-styles" href="/ccpa-opt-out"
            >Do Not Sell or Share My Personal Information</a
          >
        </li>
        <li class="mt-2 mr-6">
          <a
            class="text-base no-link-styles"
            href="/opt-out-of-use-sharing-of-sensitive-information-form"
          >
            Limit the Use of My Sensitive Personal Information
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.no-link-styles {
  @apply text-white hover:no-underline focus:no-underline cursor-pointer;
}

.force-text-sm {
  @apply text-sm;
}
</style>
