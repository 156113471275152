/* eslint-disable import/prefer-default-export */
import { money } from '@nuts/auto-delivery-sdk/dist/utils/format';
import { dollars } from '@nuts/auto-delivery-sdk/dist/utils/money';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { useRouteChange } from '@/composables/navigation/useRouteChange';
import { useCallback } from '@/composables/useCallback';
import { useCart } from '@/composables/useCart';
import { useCheckout } from '@/composables/useCheckout';
import { useDiscount } from '@/composables/useDiscount';
import { useLoginModal } from '@/composables/useLoginModal';
import { usePayment } from '@/composables/usePayment';
import { CheckoutStarted, checkoutStarted, CheckoutStartedProductsItem } from '@/rudder-typer';
import { useCustomer } from '@/stores/customer';
import { useNotifications } from '@/stores/notifications';
import { formatPurchaseItem, gtag } from '@/utils/analytics';
import { NutsRedirect } from '@/utils/exceptions';
import { getUnitName } from '@/utils/product';
import { reportError } from '@/utils/reportError';

interface Options {
  onError?: () => any | Promise<() => any>;
  onSuccess?: () => any | Promise<() => any>;
}

export function useProceedToCheckout() {
  const route = useRoute();
  const store = useStore();

  const { loadCart, lineItems } = useCart(store);
  const { cxMode, determineEntryLocation, prepareCartForCheckout } = useCheckout(store);
  const { appliedDiscountCodes } = useDiscount(store);
  const { handleOpen } = useLoginModal();
  const { navigateTo } = useRouteChange(useRouter());
  const { addNotifications } = useNotifications();
  const { findPaymentItem, estimatedAmountToCharge, estimatedTotal } = usePayment(store, cxMode);

  const goToCheckout = useCallback(async (options?: Options) => {
    try {
      await prepareCartForCheckout();
      const entryLocation = determineEntryLocation();

      if (options?.onSuccess) await options.onSuccess();
      return navigateTo(entryLocation);
    } catch (error) {
      if (error instanceof NutsRedirect) {
        if (route?.path !== error.url) {
          await navigateTo(error.url);
        } else {
          window.scrollTo({ top: 0, behavior: 'smooth' });
          loadCart(true);
        }

        if (options?.onError) await options.onError();
        const { errors } = error;
        return addNotifications({ errors });
      }
      console.error(error);
      return undefined;
    }
  });

  const checkoutType = computed(() => {
    if (cxMode.value) return 'CX';
    return useCustomer().customer ? 'Customer' : 'Guest';
  });

  const currency = 'USD';

  const checkoutEvent = computed(() => ({
    checkout_type: checkoutType.value,
    currency,
    value: money(estimatedAmountToCharge.value),
    coupon: appliedDiscountCodes.value?.[0]?.code,
    items: lineItems.value?.map(formatPurchaseItem),
  }));

  function buildCheckoutStartedPayload() {
    const products = lineItems.value?.map(
      (li): CheckoutStartedProductsItem => ({
        category: li.variant?.attributes?.find((attr) => attr.name === 'merchandisingCategory')
          ?.value?.key,
        image_url: li.titleImage?.url,
        name: li.name?.en,
        price: dollars(li.piecePrice),
        product_id: li.productKey,
        quantity: li.quantity,
        reporting_category: li.variant?.attributes?.find(
          (attr) => attr.name === 'reportingCategory',
        )?.value?.key,
        sku: li.variant.sku,
        url: li.productPath,
        variant: getUnitName(li.variant.variantName) ?? undefined,
      }),
    );

    const subtotal = findPaymentItem('Subtotal')?.amount;
    const discount = findPaymentItem('Discount')?.amount;
    const shipping = findPaymentItem('Shipping')?.amount;
    const tax = findPaymentItem('Tax')?.amount;
    const payload: CheckoutStarted = {
      coupon: checkoutEvent.value.coupon,
      currency,
      discount: discount && dollars(discount),
      products,
      revenue: subtotal && dollars(subtotal),
      shipping: shipping && dollars(shipping),
      tax: tax && dollars(tax),
      value: dollars(estimatedTotal.value),
    };

    return payload;
  }

  const signInOrGoToCheckout = useCallback(async (options?: Options) => {
    gtag('event', 'begin_checkout', checkoutEvent.value);

    try {
      const checkoutStartedPayload = buildCheckoutStartedPayload();
      checkoutStarted(checkoutStartedPayload);
    } catch (error) {
      reportError(error);
    }

    if (useCustomer().customer || cxMode.value) {
      await goToCheckout.execute(options);
    } else {
      handleOpen({ callback: useCallback(() => goToCheckout.execute(options)), isCheckout: true });
    }
  });

  const isPending = computed(() => signInOrGoToCheckout.isPending || goToCheckout.isPending);

  return {
    isPending,
    proceedToCheckout: signInOrGoToCheckout.execute,
  };
}
